export const background = {
  default: '#F8F8F8',
  paperElevation0: 'rgba(248, 250, 251, 1)',
  disabledButton: 'rgba(224, 224, 224, 1)',
  secondaryBackground: '#F9F5FF', // blue
  secondaryDarkBackground: '#FFFAF2', // orange
  secondaryDarkBorder: '#F99925',
  primaryBackground: '#ECDFFF',
  issueBackground: 'rgba(253, 237, 237, 1)',
  docBorder: '#BDBDBD',
};

export const action = {
  active: 'rgba(0, 0, 0, 0.56)',
  hover: 'rgba(0, 0, 0, 0.04)',
  selected: 'rgba(0, 0, 0, 0.08)',
  disabledBackground: 'rgba(0, 0, 0, 0.12)',
  focus: 'rgba(0, 0, 0, 0.12)',
  disabled: 'rgba(0, 0, 0, 0.38)',
  divider: 'rgba(0, 0, 0, 0.12)',
  issueText: 'rgba(95, 33, 32, 1)',
};

export const success = {
  main: 'rgba(46, 125, 50, 1)',
  dark: 'rgba(27, 94, 32, 1)',
  light: 'rgba(76, 175, 80, 1)',
};

export const info = {
  main: 'rgba(2, 136, 209, 1)',
  dark: 'rgba(1, 87, 155, 1)',
  light: 'rgba(3, 169, 244, 1)',
};

export const warning = {
  main: '#EF6C00',
  dark: 'rgba(239, 108, 0, 1)',
  light: 'rgba(255, 152, 0, 1)',
};

export const error = {
  main: 'rgba(211, 47, 47, 1)',
  dark: 'rgba(198, 40, 40, 1)',
  light: 'rgba(239, 83, 80, 1)',
};

export const secondary = {
  main: '#FDC15E',
  dark: '#EEA375',
  light: '#FDE4B9',
  contrast: '#25175F',
};

export const primary = {
  main: '#8435E9',
  dark: '#5A1ED3',
  light: '#8A3CBE',
  contrast: '#FFF',
};

export const colors = {
  white: 'rgba(255, 255, 255, 1)',
  black: 'rgba(0, 0, 0, 0.87)',
  text: 'rgba(0, 0, 0, 0.87)',
  disabled: 'rgba(0, 0, 0, 0.3)',
  underline: '#7C759F',
  contrast: '#25175F',
  background,
  action,
  success,
  info,
  warning,
  error,
  secondary,
  primary,
};
