import { BillingPeriod } from '@constants/enums';
import { CardProps } from '@pages/SubscribePage/types';

import AlternateEmailTwoToneIcon from '@mui/icons-material/AlternateEmailTwoTone';
import AutoAwesomeTwoToneIcon from '@mui/icons-material/AutoAwesomeTwoTone';
import AutoFixHighTwoToneIcon from '@mui/icons-material/AutoFixHighTwoTone';
import FolderOpenTwoToneIcon from '@mui/icons-material/FolderOpenTwoTone';

export const featureList = [
  { label: 'dashboard.subscribePage.features.id', Icon: AlternateEmailTwoToneIcon },
  { label: 'dashboard.subscribePage.features.store', Icon: FolderOpenTwoToneIcon },
  { label: 'dashboard.subscribePage.features.wizard', Icon: AutoFixHighTwoToneIcon },
  { label: 'dashboard.subscribePage.features.auto', Icon: AutoAwesomeTwoToneIcon },
];

export const cardsConfig: CardProps[] = [
  {
    id: 1,
    config: {
      text: 'dashboard.subscribePage.plans.annually.text',
      title: 'dashboard.subscribePage.plans.annually.title',
      cta: 'dashboard.subscribePage.plans.annually.cta',
      period: 'dashboard.subscribePage.plans.annually.period',
      billed: 'dashboard.subscribePage.plans.annually.billed',
      price: 280,
    },
    plan: BillingPeriod.YEAR,
  },
  {
    id: 2,
    config: {
      text: 'dashboard.subscribePage.plans.monthly.text',
      title: 'dashboard.subscribePage.plans.monthly.title',
      cta: 'dashboard.subscribePage.plans.monthly.cta',
      period: 'dashboard.subscribePage.plans.monthly.period',
      billed: 'dashboard.subscribePage.plans.monthly.billed',
      price: 35,
    },
    plan: BillingPeriod.MONTH,
  },
];
