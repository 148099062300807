import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSubscriptionsCancelCreateMutation, useSubscriptionsCreateCheckoutSessionCreateMutation } from '@api/api';
import { ERROR } from '@constants/auth';
import { API_ERROR_MSG_PATH } from '@constants/common';
import { BillingPeriod } from '@constants/enums';
import { ROUTING } from '@constants/routing';
import { useSubscription } from '@hooks/useSubscription';
import { getErrorMessage } from '@utils/getMessage';
import { useSnackbar } from 'notistack';

export const useBilling = () => {
  const snackbar = useSnackbar();
  const { t } = useTranslation();

  const { subscriptionStatus } = useSubscription();

  const [subscribeMutation, { isLoading: isLoadingSubscribeMutation, error: subscriptionError }] =
    useSubscriptionsCreateCheckoutSessionCreateMutation();
  const [cancelSubscriptionMutation, { isLoading: isLoadingCancelSubscription, error: cancelSubscriptionError }] =
    useSubscriptionsCancelCreateMutation();

  const handleCancelSubscription = useCallback(async () => {
    try {
      if (subscriptionStatus === 'inactive') return;

      const response = await cancelSubscriptionMutation();
      if (response.error) {
        snackbar.enqueueSnackbar(t('billing.errors.cancelSubscriptionError'), { variant: ERROR });
      }
      return response;
    } catch (err) {
      snackbar.enqueueSnackbar(getErrorMessage(err, API_ERROR_MSG_PATH), { variant: ERROR });
    }
  }, [cancelSubscriptionMutation, snackbar, subscriptionStatus, t]);

  const handleSubscribe = useCallback(
    async ({ period }: { period: BillingPeriod }) => {
      try {
        if (subscriptionStatus === 'active') {
          const res = await handleCancelSubscription();
          if (res?.error) return;
        }

        const uuid = crypto.randomUUID();
        const res = await subscribeMutation({
          createCheckoutSessionRequest: {
            subscription_type: period,
            success_url: `${window.location.origin}/?session_id=${uuid}`,
            cancel_url: `${window.location.origin}/${ROUTING.SUBSCRIBE}`,
          },
        });
        if (res.data) {
          const { data: resData } = res;
          return (window.location = resData.redirect_url);
        }
        if (subscriptionError) {
          snackbar.enqueueSnackbar(t('auth.subscriptionError'), { variant: ERROR });
        }
      } catch (err) {
        snackbar.enqueueSnackbar(getErrorMessage(err, API_ERROR_MSG_PATH), { variant: ERROR });
      }
    },
    [handleCancelSubscription, snackbar, subscribeMutation, subscriptionError, subscriptionStatus, t],
  );

  const isLoadingBilling = [isLoadingSubscribeMutation, isLoadingCancelSubscription].some(Boolean);

  return {
    subscriptionError,
    cancelSubscriptionError,
    isLoadingBilling,
    handleSubscribe,
    handleCancelSubscription,
  };
};
