import { FC, memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Document, Page, pdfjs } from 'react-pdf';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import NiceModal from '@ebay/nice-modal-react';
import PreviewButton from '@pages/InvoiceDetailsNew/components/PreviewColumn/components/DocumentPreview/components/PreviewButton';
import {
  StyledDocumentBodyContainer,
  StyledDocumentNameContainer,
  StyledDocViewerWrapper,
} from '@pages/InvoiceDetailsNew/components/PreviewColumn/components/DocumentPreview/styled';
import { PreviewDoc } from '@pages/InvoiceDetailsNew/components/PreviewColumn/types';
import { PreviewModalId } from '@pages/InvoiceDetailsNew/modals/PreviewModal';

import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { Box, Typography } from '@mui/material';
import FloatingViewButton from '@components/FloatingViewButton';

pdfjs.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/build/pdf.worker.min.mjs', import.meta.url).toString();

const DocumentPreview: FC<PreviewDoc> = ({ blob, fileName, fileType }) => {
  const [numPages, setNumPages] = useState<number>(0);
  const [selectedPage, setSelectedPage] = useState<number>(1);
  const { t } = useTranslation();
  const isPdf = fileType?.includes('pdf');
  const docs = [{ blob: blob as Blob, fileName: fileName as string }];

  const handlePageSelect = (pageNumber: number) => {
    setSelectedPage(pageNumber);
  };

  useEffect(() => {
    if (isPdf && blob) {
      const loadPdf = async () => {
        const pdf = await pdfjs.getDocument({ data: await blob.arrayBuffer() }).promise;
        setNumPages(pdf.numPages);
      };
      loadPdf();
    }
  }, [blob, isPdf]);
  return (
    <>
      <Box m={0} p={0} position="relative" width="100%">
        <StyledDocumentNameContainer p="12px 20px" width="100%">
          <Typography fontWeight={400} fontSize={14}>
            {fileName}
          </Typography>
        </StyledDocumentNameContainer>
        <StyledDocumentBodyContainer p={1} width="100%">
          <StyledDocViewerWrapper height="415px">
            {!isPdf && (
              <DocViewer
                pluginRenderers={DocViewerRenderers}
                documents={docs.map(file => ({
                  uri: window.URL.createObjectURL(file.blob),
                  fileName: file.fileName,
                }))}
                config={{
                  header: {
                    disableHeader: false,
                    disableFileName: true,
                    retainURLParams: true,
                  },
                }}
              />
            )}
            {isPdf && (
              <Document file={blob} onLoadError={console.error}>
                <Page pageNumber={selectedPage} scale={1} />
              </Document>
            )}
          </StyledDocViewerWrapper>
        </StyledDocumentBodyContainer>

        <Box position="absolute" bottom={16} right={16}>
          <FloatingViewButton
            size="small"
            onClick={async e => {
              e.stopPropagation();
              await NiceModal.show(PreviewModalId, { docs, selectedPage, isPdf });
            }}
            icon={RemoveRedEyeIcon}
            locale={t('common.view')}
          />
        </Box>
      </Box>
      <Box my={1} display="flex" flexWrap="wrap" gap={1}>
        {Array.from({ length: numPages }, (_, i) => i + 1).map(pageNum => (
          <PreviewButton
            key={pageNum}
            blob={blob}
            fileName={fileName}
            fileType={fileType}
            isSelected={pageNum === selectedPage}
            onClick={() => handlePageSelect(pageNum)}
            isPdf={isPdf}
            pageNumber={pageNum}
          />
        ))}
      </Box>
    </>
  );
};

export default memo(DocumentPreview);
