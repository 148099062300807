import { emptyBaseSplitApi as api } from './baseApi';
const injectedRtkApi = api.injectEndpoints({
  endpoints: build => ({
    apiCategoriesList: build.query<ApiCategoriesListApiResponse, ApiCategoriesListApiArg>({
      query: () => ({ url: `/api/categories/` }),
    }),
    apiHealthCheckRetrieve: build.query<ApiHealthCheckRetrieveApiResponse, ApiHealthCheckRetrieveApiArg>({
      query: () => ({ url: `/api/health-check/` }),
    }),
    apiInvoicesList: build.query<ApiInvoicesListApiResponse, ApiInvoicesListApiArg>({
      query: queryArg => ({ url: `/api/invoices/`, params: { limit: queryArg.limit, offset: queryArg.offset } }),
    }),
    apiInvoicesDownloadRetrieve: build.query<ApiInvoicesDownloadRetrieveApiResponse, ApiInvoicesDownloadRetrieveApiArg>(
      {
        query: queryArg => ({ url: `/api/invoices/${queryArg.id}/download/` }),
      },
    ),
    apiInvoicesRevertCreate: build.mutation<ApiInvoicesRevertCreateApiResponse, ApiInvoicesRevertCreateApiArg>({
      query: queryArg => ({
        url: `/api/invoices/${queryArg.id}/revert/`,
        method: 'POST',
        body: queryArg.invoiceRevertFieldRequest,
      }),
    }),
    apiInvoicesChangesList: build.query<ApiInvoicesChangesListApiResponse, ApiInvoicesChangesListApiArg>({
      query: queryArg => ({ url: `/api/invoices/${queryArg.invoiceId}/changes/` }),
    }),
    apiInvoicesRetrieve: build.query<ApiInvoicesRetrieveApiResponse, ApiInvoicesRetrieveApiArg>({
      query: queryArg => ({ url: `/api/invoices/${queryArg.id}/` }),
    }),
    apiInvoicesDestroy: build.mutation<ApiInvoicesDestroyApiResponse, ApiInvoicesDestroyApiArg>({
      query: queryArg => ({ url: `/api/invoices/${queryArg.id}/`, method: 'DELETE' }),
    }),
    apiInvoicesUpdatePartialUpdate: build.mutation<
      ApiInvoicesUpdatePartialUpdateApiResponse,
      ApiInvoicesUpdatePartialUpdateApiArg
    >({
      query: queryArg => ({
        url: `/api/invoices/${queryArg.id}/update/`,
        method: 'PATCH',
        body: queryArg.patchedInvoiceUpdateRequest,
      }),
    }),
    apiInvoicesExportRetrieve: build.query<ApiInvoicesExportRetrieveApiResponse, ApiInvoicesExportRetrieveApiArg>({
      query: () => ({ url: `/api/invoices/export/` }),
    }),
    apiReasonsList: build.query<ApiReasonsListApiResponse, ApiReasonsListApiArg>({
      query: () => ({ url: `/api/reasons/` }),
    }),
    authPasswordChangeCreate: build.mutation<AuthPasswordChangeCreateApiResponse, AuthPasswordChangeCreateApiArg>({
      query: queryArg => ({ url: `/auth/password/change/`, method: 'POST', body: queryArg.passwordChangeRequest }),
    }),
    authPasswordResetCreate: build.mutation<AuthPasswordResetCreateApiResponse, AuthPasswordResetCreateApiArg>({
      query: queryArg => ({ url: `/auth/password/reset/`, method: 'POST', body: queryArg.passwordResetRequest }),
    }),
    authPasswordResetConfirmCreate: build.mutation<
      AuthPasswordResetConfirmCreateApiResponse,
      AuthPasswordResetConfirmCreateApiArg
    >({
      query: queryArg => ({
        url: `/auth/password/reset/confirm/`,
        method: 'POST',
        body: queryArg.passwordResetConfirmRequest,
      }),
    }),
    authSigninCreate: build.mutation<AuthSigninCreateApiResponse, AuthSigninCreateApiArg>({
      query: queryArg => ({ url: `/auth/signin/`, method: 'POST', body: queryArg.loginRequest }),
    }),
    authSignoutCreate: build.mutation<AuthSignoutCreateApiResponse, AuthSignoutCreateApiArg>({
      query: () => ({ url: `/auth/signout/`, method: 'POST' }),
    }),
    authSignupCreate: build.mutation<AuthSignupCreateApiResponse, AuthSignupCreateApiArg>({
      query: queryArg => ({ url: `/auth/signup/`, method: 'POST', body: queryArg.customRegisterRequest }),
    }),
    authSignupResendEmailCreate: build.mutation<
      AuthSignupResendEmailCreateApiResponse,
      AuthSignupResendEmailCreateApiArg
    >({
      query: queryArg => ({
        url: `/auth/signup/resend-email/`,
        method: 'POST',
        body: queryArg.resendEmailVerificationRequest,
      }),
    }),
    authSignupVerifyEmailCreate: build.mutation<
      AuthSignupVerifyEmailCreateApiResponse,
      AuthSignupVerifyEmailCreateApiArg
    >({
      query: queryArg => ({ url: `/auth/signup/verify-email/`, method: 'POST', body: queryArg.body }),
    }),
    authTokenRefreshCreate: build.mutation<AuthTokenRefreshCreateApiResponse, AuthTokenRefreshCreateApiArg>({
      query: queryArg => ({ url: `/auth/token/refresh/`, method: 'POST', body: queryArg.tokenRefreshRequest }),
    }),
    authUserRetrieve: build.query<AuthUserRetrieveApiResponse, AuthUserRetrieveApiArg>({
      query: () => ({ url: `/auth/user/` }),
    }),
    authUserUpdate: build.mutation<AuthUserUpdateApiResponse, AuthUserUpdateApiArg>({
      query: queryArg => ({ url: `/auth/user/`, method: 'PUT', body: queryArg.customUserDetailsRequest }),
    }),
    authUserPartialUpdate: build.mutation<AuthUserPartialUpdateApiResponse, AuthUserPartialUpdateApiArg>({
      query: queryArg => ({ url: `/auth/user/`, method: 'PATCH', body: queryArg.patchedCustomUserDetailsRequest }),
    }),
    emailsList: build.query<EmailsListApiResponse, EmailsListApiArg>({
      query: queryArg => ({ url: `/emails/`, params: { email: queryArg.email } }),
    }),
    emailsInvoiceCreate: build.mutation<EmailsInvoiceCreateApiResponse, EmailsInvoiceCreateApiArg>({
      query: queryArg => ({ url: `/emails/invoice/`, method: 'POST', body: queryArg.invoiceEmailCreateRequest }),
    }),
    emailsInvoiceCheckUniqueCreate: build.mutation<
      EmailsInvoiceCheckUniqueCreateApiResponse,
      EmailsInvoiceCheckUniqueCreateApiArg
    >({
      query: queryArg => ({ url: `/emails/invoice/check-unique`, method: 'POST', body: queryArg.inputRequest }),
    }),
    emailsMsSubscriptionRenewRetrieve: build.query<
      EmailsMsSubscriptionRenewRetrieveApiResponse,
      EmailsMsSubscriptionRenewRetrieveApiArg
    >({
      query: () => ({ url: `/emails/ms-subscription/renew/` }),
    }),
    parserUploadCreate: build.mutation<ParserUploadCreateApiResponse, ParserUploadCreateApiArg>({
      query: queryArg => ({ url: `/parser/upload/`, method: 'POST', body: queryArg.body }),
    }),
    subscriptionsRetrieve: build.query<SubscriptionsRetrieveApiResponse, SubscriptionsRetrieveApiArg>({
      query: () => ({ url: `/subscriptions/` }),
    }),
    subscriptionsCancelCreate: build.mutation<SubscriptionsCancelCreateApiResponse, SubscriptionsCancelCreateApiArg>({
      query: () => ({ url: `/subscriptions/cancel/`, method: 'POST' }),
    }),
    subscriptionsCreateCheckoutSessionCreate: build.mutation<
      SubscriptionsCreateCheckoutSessionCreateApiResponse,
      SubscriptionsCreateCheckoutSessionCreateApiArg
    >({
      query: queryArg => ({
        url: `/subscriptions/create-checkout-session/`,
        method: 'POST',
        body: queryArg.createCheckoutSessionRequest,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as api };
export type ApiCategoriesListApiResponse = /** status 200  */ CategoryRead[];
export type ApiCategoriesListApiArg = void;
export type ApiHealthCheckRetrieveApiResponse = /** status 200  */ {
  status?: string;
};
export type ApiHealthCheckRetrieveApiArg = void;
export type ApiInvoicesListApiResponse = /** status 200  */ PaginatedInvoiceListRead;
export type ApiInvoicesListApiArg = {
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
};
export type ApiInvoicesDownloadRetrieveApiResponse = /** status 200  */ Blob;
export type ApiInvoicesDownloadRetrieveApiArg = {
  /** The ID of the invoice to download */
  id: number;
};
export type ApiInvoicesRevertCreateApiResponse = /** status 200  */ InvoiceRead;
export type ApiInvoicesRevertCreateApiArg = {
  id: number;
  invoiceRevertFieldRequest: InvoiceRevertFieldRequest;
};
export type ApiInvoicesChangesListApiResponse = /** status 200  */ InvoiceChangeLogRead[];
export type ApiInvoicesChangesListApiArg = {
  invoiceId: number;
};
export type ApiInvoicesRetrieveApiResponse = /** status 200  */ InvoiceRead;
export type ApiInvoicesRetrieveApiArg = {
  id: number;
};
export type ApiInvoicesDestroyApiResponse = unknown;
export type ApiInvoicesDestroyApiArg = {
  id: number;
};
export type ApiInvoicesUpdatePartialUpdateApiResponse = /** status 200  */ InvoiceUpdate;
export type ApiInvoicesUpdatePartialUpdateApiArg = {
  id: number;
  patchedInvoiceUpdateRequest: PatchedInvoiceUpdateRequest;
};
export type ApiInvoicesExportRetrieveApiResponse = /** status 200  */ Blob;
export type ApiInvoicesExportRetrieveApiArg = void;
export type ApiReasonsListApiResponse = /** status 200  */ ReasonRead[];
export type ApiReasonsListApiArg = void;
export type AuthPasswordChangeCreateApiResponse = /** status 200  */ RestAuthDetailRead;
export type AuthPasswordChangeCreateApiArg = {
  passwordChangeRequest: PasswordChangeRequest;
};
export type AuthPasswordResetCreateApiResponse = /** status 200  */ PasswordReset;
export type AuthPasswordResetCreateApiArg = {
  passwordResetRequest: PasswordResetRequest;
};
export type AuthPasswordResetConfirmCreateApiResponse = /** status 200  */ RestAuthDetailRead;
export type AuthPasswordResetConfirmCreateApiArg = {
  passwordResetConfirmRequest: PasswordResetConfirmRequest;
};
export type AuthSigninCreateApiResponse = /** status 200  */ JwtRead;
export type AuthSigninCreateApiArg = {
  loginRequest: LoginRequest;
};
export type AuthSignoutCreateApiResponse = /** status 200  */ RestAuthDetailRead;
export type AuthSignoutCreateApiArg = void;
export type AuthSignupCreateApiResponse = /** status 201  */ CustomRegister;
export type AuthSignupCreateApiArg = {
  customRegisterRequest: CustomRegisterRequestWrite;
};
export type AuthSignupResendEmailCreateApiResponse = /** status 201  */ RestAuthDetailRead;
export type AuthSignupResendEmailCreateApiArg = {
  resendEmailVerificationRequest: ResendEmailVerificationRequest;
};
export type AuthSignupVerifyEmailCreateApiResponse = /** status 200 Email confirmed successfully */ {
  [key: string]: any;
};
export type AuthSignupVerifyEmailCreateApiArg = {
  body: {
    /** The confirmation key sent via email. */
    key: string;
  };
};
export type AuthTokenRefreshCreateApiResponse = /** status 200  */ TokenRefreshRead;
export type AuthTokenRefreshCreateApiArg = {
  tokenRefreshRequest: TokenRefreshRequestWrite;
};
export type AuthUserRetrieveApiResponse = /** status 200  */ CustomUserDetailsRead;
export type AuthUserRetrieveApiArg = void;
export type AuthUserUpdateApiResponse = /** status 200  */ CustomUserDetailsRead;
export type AuthUserUpdateApiArg = {
  customUserDetailsRequest: CustomUserDetailsRequest;
};
export type AuthUserPartialUpdateApiResponse = /** status 200  */ CustomUserDetailsRead;
export type AuthUserPartialUpdateApiArg = {
  patchedCustomUserDetailsRequest: PatchedCustomUserDetailsRequest;
};
export type EmailsListApiResponse = /** status 200  */ EmailRead[];
export type EmailsListApiArg = {
  /** The email address to filter by */
  email: string;
};
export type EmailsInvoiceCreateApiResponse = /** status 201  */ InvoiceEmailCreate;
export type EmailsInvoiceCreateApiArg = {
  invoiceEmailCreateRequest: InvoiceEmailCreateRequest;
};
export type EmailsInvoiceCheckUniqueCreateApiResponse = /** status 200  */ {
  is_available?: boolean;
};
export type EmailsInvoiceCheckUniqueCreateApiArg = {
  inputRequest: InputRequest;
};
export type EmailsMsSubscriptionRenewRetrieveApiResponse = /** status 200  */ {
  [key: string]: any;
};
export type EmailsMsSubscriptionRenewRetrieveApiArg = void;
export type ParserUploadCreateApiResponse = /** status 201  */ {
  [key: string]: any;
};
export type ParserUploadCreateApiArg = {
  body: {
    file?: Blob;
  };
};
export type SubscriptionsRetrieveApiResponse = /** status 200  */ SubscriptionRead;
export type SubscriptionsRetrieveApiArg = void;
export type SubscriptionsCancelCreateApiResponse = unknown;
export type SubscriptionsCancelCreateApiArg = void;
export type SubscriptionsCreateCheckoutSessionCreateApiResponse = /** status 200  */ {
  [key: string]: any;
};
export type SubscriptionsCreateCheckoutSessionCreateApiArg = {
  createCheckoutSessionRequest: CreateCheckoutSessionRequest;
};
export type Category = {
  name: string;
};
export type CategoryRead = {
  id: number;
  name: string;
  subcategories: {
    id?: number;
    name?: string;
    subcategories?: CategoryRead[];
  }[];
};
export type InvoiceItem = {
  id: number;
  description: string;
  quantity?: number | null;
  unit_price?: string | null;
  amount?: string | null;
  currency_symbol?: string | null;
  currency_code?: string | null;
  vat?: string | null;
  date?: string | null;
  source?: string | null;
};
export type ClaimedOriginEnum = 'web_extension' | 'manual_entry' | 'imported';
export type BlankEnum = '';
export type NullEnum = null;
export type Invoice = {
  invoice_id: string;
  invoice_date?: string | null;
  due_date?: string | null;
  vendor_name?: string | null;
  vendor_address?: string | null;
  customer_name?: string | null;
  customer_address?: string | null;
  items: InvoiceItem[];
  total_amount?: string | null;
  sub_total?: string | null;
  total_tax?: string | null;
  currency?: string | null;
  currency_symbol?: string | null;
  abn?: string | null;
  service_start_date?: string | null;
  service_end_date?: string | null;
  service_exact_date?: string | null;
  vendor_email: string;
  recipient_email: string;
  vat?: string | null;
  is_manual_upload?: boolean;
  form_submitted_code?: string | null;
  note?: string | null;
  is_paid?: boolean;
  paid_date?: string | null;
  paid_reference_number?: string | null;
  is_claimed?: boolean;
  claimed_date?: string | null;
  claimed_reference_number?: string | null;
  claimed_origin?: (ClaimedOriginEnum | BlankEnum | NullEnum) | null;
  description?: string | null;
};
export type Reason = {
  name: string;
};
export type ReasonRead = {
  id: number;
  name: string;
};
export type InvoiceRead = {
  id: number;
  invoice_id: string;
  invoice_date?: string | null;
  due_date?: string | null;
  vendor_name?: string | null;
  vendor_address?: string | null;
  customer_name?: string | null;
  customer_address?: string | null;
  items: InvoiceItem[];
  total_amount?: string | null;
  sub_total?: string | null;
  total_tax?: string | null;
  currency?: string | null;
  currency_symbol?: string | null;
  abn?: string | null;
  service_start_date?: string | null;
  service_end_date?: string | null;
  service_exact_date?: string | null;
  updated_at: string;
  created_at: string;
  vendor_email: string;
  recipient_email: string;
  vat?: string | null;
  is_manual_upload?: boolean;
  form_submitted_code?: string | null;
  category: CategoryRead;
  reason: ReasonRead;
  note?: string | null;
  is_paid?: boolean;
  paid_date?: string | null;
  paid_reference_number?: string | null;
  is_claimed?: boolean;
  claimed_date?: string | null;
  claimed_reference_number?: string | null;
  claimed_origin?: (ClaimedOriginEnum | BlankEnum | NullEnum) | null;
  description?: string | null;
};
export type PaginatedInvoiceList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: Invoice[];
};
export type PaginatedInvoiceListRead = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: InvoiceRead[];
};
export type InvoiceRevertFieldRequest = {
  /** The name of the field to revert */
  field_name: string;
  /** The ID of the invoice item (if reverting an item field) */
  item_id?: number | null;
};
export type InvoiceChangeLog = {
  field_name: string;
  item_id?: number | null;
  old_value?: any | null;
  new_value?: any | null;
  invoice: number;
  changed_by?: number | null;
};
export type InvoiceChangeLogRead = {
  id: number;
  field_name: string;
  item_id?: number | null;
  old_value?: any | null;
  new_value?: any | null;
  changed_at: string;
  created_at: string;
  updated_at: string;
  invoice: number;
  changed_by?: number | null;
};
export type InvoiceUpdate = {
  invoice_id: string;
  invoice_date?: string | null;
  due_date?: string | null;
  vendor_name?: string | null;
  vendor_address?: string | null;
  customer_name?: string | null;
  customer_address?: string | null;
  items: InvoiceItem[];
  total_amount?: string | null;
  sub_total?: string | null;
  total_tax?: string | null;
  currency?: string | null;
  currency_symbol?: string | null;
  abn?: string | null;
  service_start_date?: string | null;
  service_end_date?: string | null;
  service_exact_date?: string | null;
  vat?: string | null;
  form_submitted_code?: string | null;
  category_id?: number | null;
  reason_id?: number | null;
  note?: string | null;
  is_paid?: boolean;
  paid_date?: string | null;
  paid_reference_number?: string | null;
  is_claimed?: boolean;
  claimed_date?: string | null;
  claimed_reference_number?: string | null;
  claimed_origin?: (ClaimedOriginEnum | BlankEnum | NullEnum) | null;
  description?: string | null;
};
export type InvoiceItemRequest = {
  id: number;
  description: string;
  quantity?: number | null;
  unit_price?: string | null;
  amount?: string | null;
  currency_symbol?: string | null;
  currency_code?: string | null;
  vat?: string | null;
  date?: string | null;
  source?: string | null;
};
export type PatchedInvoiceUpdateRequest = {
  invoice_id?: string;
  invoice_date?: string | null;
  due_date?: string | null;
  vendor_name?: string | null;
  vendor_address?: string | null;
  customer_name?: string | null;
  customer_address?: string | null;
  items?: InvoiceItemRequest[];
  total_amount?: string | null;
  sub_total?: string | null;
  total_tax?: string | null;
  currency?: string | null;
  currency_symbol?: string | null;
  abn?: string | null;
  service_start_date?: string | null;
  service_end_date?: string | null;
  service_exact_date?: string | null;
  vat?: string | null;
  form_submitted_code?: string | null;
  category_id?: number | null;
  reason_id?: number | null;
  note?: string | null;
  is_paid?: boolean;
  paid_date?: string | null;
  paid_reference_number?: string | null;
  is_claimed?: boolean;
  claimed_date?: string | null;
  claimed_reference_number?: string | null;
  claimed_origin?: (ClaimedOriginEnum | BlankEnum | NullEnum) | null;
  description?: string | null;
};
export type RestAuthDetail = {};
export type RestAuthDetailRead = {
  detail: string;
};
export type PasswordChangeRequest = {
  new_password1: string;
  new_password2: string;
};
export type PasswordReset = {
  /** User's email address */
  email: string;
  /** Frontend URL for password reset */
  frontend_url: string;
};
export type PasswordResetRequest = {
  /** User's email address */
  email: string;
  /** Frontend URL for password reset */
  frontend_url: string;
};
export type PasswordResetConfirmRequest = {
  new_password1: string;
  new_password2: string;
  uid: string;
  token: string;
};
export type CustomUserDetails = {
  /** Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only. */
  username: string;
  first_name?: string;
  last_name?: string;
  invoice_email?: string | null;
  phone_number?: string | null;
};
export type CustomUserDetailsRead = {
  pk: number;
  /** Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only. */
  username: string;
  email: string;
  first_name?: string;
  last_name?: string;
  invoice_email?: string | null;
  phone_number?: string | null;
};
export type Jwt = {
  access: string;
  refresh: string;
  user: CustomUserDetails;
};
export type JwtRead = {
  access: string;
  refresh: string;
  user: CustomUserDetailsRead;
};
export type LoginRequest = {
  username?: string;
  email?: string;
  password: string;
};
export type CustomRegister = {
  username?: string;
  email: string;
  first_name: string;
  last_name: string;
  phone_number?: string;
};
export type CustomRegisterRequest = {
  username?: string;
  email: string;
  first_name: string;
  last_name: string;
  phone_number?: string;
};
export type CustomRegisterRequestWrite = {
  username?: string;
  email: string;
  password1: string;
  password2: string;
  first_name: string;
  last_name: string;
  phone_number?: string;
};
export type ResendEmailVerificationRequest = {
  email: string;
};
export type TokenRefresh = {};
export type TokenRefreshRead = {
  access: string;
};
export type TokenRefreshRequest = {};
export type TokenRefreshRequestWrite = {
  refresh: string;
};
export type CustomUserDetailsRequest = {
  /** Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only. */
  username: string;
  first_name?: string;
  last_name?: string;
  invoice_email?: string | null;
  phone_number?: string | null;
};
export type PatchedCustomUserDetailsRequest = {
  /** Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only. */
  username?: string;
  first_name?: string;
  last_name?: string;
  invoice_email?: string | null;
  phone_number?: string | null;
};
export type Email = {
  sender: string;
  recipient: string;
  recipients: any;
  message_id: string;
  has_attachments: boolean;
  subject?: string | null;
  body?: string | null;
  cc_recipients?: any | null;
  bcc_recipients?: any | null;
  message_created?: string | null;
  sent_datetime?: string | null;
  reply_to?: any | null;
  message_web_link?: string | null;
};
export type EmailAttachment = {
  content_type: string;
  is_inline: boolean;
  last_modified_datetime: string;
  name: string;
  size: number;
  storage_file_path: string;
  storage_file_id: string;
  content_location?: string | null;
  content_id?: string | null;
  email: number;
};
export type EmailAttachmentRead = {
  id: number;
  content_type: string;
  is_inline: boolean;
  last_modified_datetime: string;
  name: string;
  size: number;
  storage_file_path: string;
  storage_file_id: string;
  content_location?: string | null;
  content_id?: string | null;
  created_at: string;
  updated_at: string;
  email: number;
};
export type EmailRead = {
  id: number;
  attachments: EmailAttachmentRead[];
  sender: string;
  recipient: string;
  recipients: any;
  message_id: string;
  has_attachments: boolean;
  subject?: string | null;
  body?: string | null;
  cc_recipients?: any | null;
  bcc_recipients?: any | null;
  message_created?: string | null;
  sent_datetime?: string | null;
  reply_to?: any | null;
  message_web_link?: string | null;
  created_at: string;
  updated_at: string;
};
export type InvoiceEmailCreate = {
  email: string;
};
export type InvoiceEmailCreateRequest = {
  email: string;
};
export type InputRequest = {
  email: string;
};
export type Subscription = {};
export type SubscriptionTypeEnum = 'annual' | 'monthly';
export type StatusEnum = 'active' | 'inactive';
export type SubscriptionRead = {
  subscription_type: SubscriptionTypeEnum;
  is_trial: boolean;
  trial_days_left: number;
  days_left: number;
  status: StatusEnum;
};
export type CreateCheckoutSessionRequest = {
  subscription_type: SubscriptionTypeEnum;
  success_url: string;
  cancel_url: string;
};
export const {
  useApiCategoriesListQuery,
  useApiHealthCheckRetrieveQuery,
  useApiInvoicesListQuery,
  useApiInvoicesDownloadRetrieveQuery,
  useApiInvoicesRevertCreateMutation,
  useApiInvoicesChangesListQuery,
  useApiInvoicesRetrieveQuery,
  useApiInvoicesDestroyMutation,
  useApiInvoicesUpdatePartialUpdateMutation,
  useApiInvoicesExportRetrieveQuery,
  useApiReasonsListQuery,
  useAuthPasswordChangeCreateMutation,
  useAuthPasswordResetCreateMutation,
  useAuthPasswordResetConfirmCreateMutation,
  useAuthSigninCreateMutation,
  useAuthSignoutCreateMutation,
  useAuthSignupCreateMutation,
  useAuthSignupResendEmailCreateMutation,
  useAuthSignupVerifyEmailCreateMutation,
  useAuthTokenRefreshCreateMutation,
  useAuthUserRetrieveQuery,
  useAuthUserUpdateMutation,
  useAuthUserPartialUpdateMutation,
  useEmailsListQuery,
  useEmailsInvoiceCreateMutation,
  useEmailsInvoiceCheckUniqueCreateMutation,
  useEmailsMsSubscriptionRenewRetrieveQuery,
  useParserUploadCreateMutation,
  useSubscriptionsRetrieveQuery,
  useSubscriptionsCancelCreateMutation,
  useSubscriptionsCreateCheckoutSessionCreateMutation,
} = injectedRtkApi;
