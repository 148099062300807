import { FC, memo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { BillingPeriod } from '@constants/enums';
import { AUD, YEARLY_SAVE_TOTAL } from '@pages/SubscribePage/constants';
import {
  CardContainer,
  StyledPrimaryBox,
  StyledTypographyContrast,
  StyledTypographyPrimaryDark,
} from '@pages/SubscribePage/styled';
import { CardProps } from '@pages/SubscribePage/types';

import { Box, FormControlLabel, Radio, Typography } from '@mui/material';

const Card: FC<CardProps & { isSelected: boolean; onPeriodChange: (period: BillingPeriod) => void }> = ({
  config,
  isSelected,
  plan,
  onPeriodChange,
}) => {
  const { t } = useTranslation();
  const { text, title, cta, period, price } = config;

  return (
    <CardContainer elevation={isSelected ? 9 : 0} isSelected={isSelected}>
      <StyledPrimaryBox py={2} display="flex" pl={5} width="100%" isSelected={isSelected}>
        <Typography fontSize={14} fontWeight={500}>
          {t(cta)}
        </Typography>
      </StyledPrimaryBox>
      <Box display="flex" flexDirection="column" pl={5} pr={1} pt={2.5}>
        <Box display="flex" gap={1} alignItems="center" ml="-8px">
          <FormControlLabel
            value={isSelected}
            control={
              <Radio
                checked={isSelected}
                onChange={() => onPeriodChange(plan as BillingPeriod)}
                value={plan}
                name="radio-buttons"
                inputProps={{ 'aria-label': `${plan} plan` }}
              />
            }
            label={
              <StyledTypographyContrast variant="h6" fontWeight={500} fontFamily="WFVisualSans">
                {t(title)}
              </StyledTypographyContrast>
            }
          />
        </Box>

        <StyledTypographyContrast fontSize={20} fontWeight={400} mt={2.5}>
          {`$${price.toFixed(2)} ${AUD} ${t(period)}`}
        </StyledTypographyContrast>

        <StyledTypographyContrast fontSize={14} fontWeight={400} mt={2} pb={4}>
          <Trans
            i18nKey={text}
            components={{
              bold: <StyledTypographyPrimaryDark fontWeight={600} variant="subtitle1" component="span" />,
            }}
            values={{
              total: YEARLY_SAVE_TOTAL.toFixed(2),
            }}
          />
        </StyledTypographyContrast>
      </Box>
    </CardContainer>
  );
};

export default memo(Card);
