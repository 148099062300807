import { Box, BoxProps, ButtonProps } from '@mui/material';
import { styled, Theme } from '@mui/material/styles';

export const StyledPreviewButtonBox = styled(Box)<BoxProps & ButtonProps & { theme?: Theme; isSelected?: boolean }>(
  ({ theme, isSelected }) => ({
    border: isSelected ? `2px solid ${theme.colors.primary.main}` : '2px solid transparent',
  }),
);

export const PageNumberBlock = styled('div')`
  position: absolute;
  bottom: -3px;
  right: 0;
  background-color: rgba(217, 217, 217, 1);
  color: black;
  padding-top: 4px;
  padding-left: 11px;
  font-size: 14px;
  font-weight: 500;
  content: '';
  height: 28px;
  width: 28px;
`;
