export const capitalize = (str?: string | string[]) => {
  if (!str) return '';

  const capitalizeWord = (word: string) => word.charAt(0).toUpperCase() + word.slice(1);

  if (Array.isArray(str)) {
    return str.map(word => capitalizeWord(word)).join(' ');
  }

  return str
    .split(' ')
    .map(word => capitalizeWord(word))
    .join(' ');
};
