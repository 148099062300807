import { CategoryRead, InvoiceRead, ReasonRead } from '@api/api';

import { theme } from '@components/theme';

import { Option } from '@/globalTypes';

export const requiredFieldsMap = [
  { name: 'description', hasAlternative: false },
  { name: 'total_amount', hasAlternative: false },
  { name: 'vendor_name', hasAlternative: false },
  { name: 'service_end_date', hasAlternative: true, alternative: ['service_exact_date'] },
  { name: 'service_start_date', hasAlternative: true, alternative: ['service_exact_date'] },
  { name: 'service_exact_date', hasAlternative: true, alternative: ['service_start_date', 'service_end_date'] },
];

export const getReasonsOptions = (reasonRead?: ReasonRead[]) =>
  reasonRead?.map(reason => ({ id: reason.id, label: reason.name, value: reason.name })) || [];

export function findCategoryOption(
  categoryData: { id: number; name: string },
  options: CategoryRead[],
): Option | undefined {
  const matchingCategory = options.find(option => option.id === categoryData.id);
  return matchingCategory
    ? { id: matchingCategory.id, label: matchingCategory.name, value: matchingCategory.name }
    : undefined;
}

export function getCategoriesOptions(categories?: CategoryRead[]): Option[] {
  return (
    categories?.map(category => ({
      id: category.id as number,
      label: category.name,
      value: category.name as string,
    })) || []
  );
}

export function findReasonOption(reasonData: { id: number; name: string }, options: ReasonRead[]): Option | undefined {
  const match = options.find(option => option.id === reasonData.id);
  return match ? { id: match.id, label: match.name, value: match.name } : undefined;
}

export const getMissingFields = (data?: InvoiceRead): Array<string> | [] => {
  if (!data) return [];
  const result = new Set<string>();

  requiredFieldsMap.forEach(({ name, hasAlternative, alternative }) => {
    const value = data[name as keyof InvoiceRead];

    if (!value) {
      if (!hasAlternative) {
        result.add(name);
      } else if (hasAlternative && alternative) {
        const hasAlternativeValue = alternative.some(altName => data[altName as keyof InvoiceRead]);
        if (!hasAlternativeValue) {
          result.add(name);
        }
      }
    }
  });

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { service_exact_date, service_start_date, service_end_date } = data;

  if (!service_exact_date && (!service_start_date || !service_end_date)) {
    if (!service_start_date) result.add('service_start_date');
    if (!service_end_date) result.add('service_end_date');
  }

  return Array.from(result);
};

export const getDatePickerInputStyles = (fieldValue: string | Date | boolean) => ({
  '& .MuiOutlinedInput-notchedOutline': {
    border: `2px solid ${fieldValue ? '#F1F1F1 !important' : `${theme.colors.warning.main} !important`}`,
  },
  '& .MuiInputBase-input': {
    position: 'relative',
    zIndex: 1,
  },
});
