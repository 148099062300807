import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  StyledPaymentDetailsBox,
  StyledProceedButton,
  StyledWrappedTypography,
} from '@pages/SubscribePage/components/styled';
import { StyledTypographyContrast } from '@pages/SubscribePage/styled';
import { PaymentInfo } from '@pages/SubscribePage/types';

import { Box, Typography } from '@mui/material';
import LogoWhite from '@components/LogoWhite';

const SubscriptionSummary: FC<{
  info: PaymentInfo;
  handleProceed: () => Promise<void>;
  isLoadingMutation: boolean;
  isSamePackage: boolean;
}> = ({ info, handleProceed, isLoadingMutation, isSamePackage }) => {
  const { t } = useTranslation();

  const paymentDetails = [
    { label: 'Name', value: info.name },
    { label: 'Email address', value: info.email },
    { label: 'Billing amount', value: info.amount },
    { label: 'Billing frequency', value: info.frequency },
  ];

  return (
    <Box px={3} pt={2} pb={3} maxWidth={450}>
      <Box ml="-8px">
        <LogoWhite />
      </Box>
      <Typography variant="h4" fontSize={32} fontFamily="WFVisualSans" mt={2} mb={3}>
        {t('dashboard.subscribePage.subscriptionSummary')}
      </Typography>
      <Box display="flex" flexDirection="column" gap={1.5}>
        {paymentDetails.map((detail, index) => (
          <StyledPaymentDetailsBox
            key={detail.value}
            display="flex"
            justifyContent="space-between"
            pb={index !== paymentDetails.length - 1 ? 1.5 : 0}
            isLast={index === paymentDetails.length - 1}
          >
            <Typography variant="subtitle2" fontWeight={400}>
              {detail.label}
            </Typography>
            <StyledWrappedTypography variant="subtitle2" fontWeight={400}>
              {detail.value}
            </StyledWrappedTypography>
          </StyledPaymentDetailsBox>
        ))}
      </Box>
      <Box mt={4} display="flex" flexDirection="column" gap={1} maxWidth={380}>
        <Typography fontWeight={400} fontSize={12}>
          {t('dashboard.subscribePage.termsOfSubscriptionTitle')}
        </Typography>
        <Typography variant="caption" fontSize={10}>
          {t('dashboard.subscribePage.termsOfSubscriptionText')}
        </Typography>
      </Box>
      <Box mt={4} display="flex" width="100%" justifyContent="center">
        <StyledProceedButton
          color="primary"
          fullWidth
          size="large"
          onClick={handleProceed}
          loading={isLoadingMutation}
          disabled={isSamePackage}
        >
          <StyledTypographyContrast fontSize={15} fontWeight={500}>
            {isSamePackage ? t('dashboard.subscribePage.samePackage') : t('dashboard.subscribePage.proceedToPayment')}
          </StyledTypographyContrast>
        </StyledProceedButton>
      </Box>
    </Box>
  );
};

export default memo(SubscriptionSummary);
