import { FC, memo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useApiInvoicesUpdatePartialUpdateMutation } from '@api/api';
import { ERROR } from '@constants/auth';
import { API_ERROR_MSG_PATH } from '@constants/common';
import NiceModal from '@ebay/nice-modal-react';
import AddDetailsButton from '@pages/InvoiceDetailsNew/components/EditableColumn/components/AddDetailsButton';
import PaymentDetailsEdit from '@pages/InvoiceDetailsNew/components/EditableColumn/components/PaymentDetails/PaymentDetailsEdit';
import { StyledTitle } from '@pages/InvoiceDetailsNew/components/EditableColumn/components/PaymentDetails/styled';
import { StyledEditSectionButton } from '@pages/InvoiceDetailsNew/components/EditableColumn/styled';
import { PAYMENT_DATE_FIELD, PAYMENT_REFERENCE_NUMBER } from '@pages/InvoiceDetailsNew/constants';
import { useInvoiceDetailsNewContext } from '@pages/InvoiceDetailsNew/context';
import { AddDateModalId } from '@pages/InvoiceDetailsNew/modals/AddDateModal';
import { AddNumberModalId } from '@pages/InvoiceDetailsNew/modals/AddNumberModal';
import { convertIsoToReadable } from '@utils/formatTime';
import { getErrorMessage } from '@utils/getMessage';
import { useSnackbar } from 'notistack';

import EditIcon from '@mui/icons-material/Edit';
import { Box, Typography } from '@mui/material';

const PaymentDetails: FC<{ loading?: boolean }> = ({ loading }) => {
  const { t } = useTranslation();
  const { watch } = useFormContext();
  const {
    setReferenceNumber,
    setPaymentDate,
    editPayment,
    toggleEdit,
    setEditPayment,
    handleResetForm,
    referenceNumber,
    paymentDate,
    invoiceId,
    invoiceData,
  } = useInvoiceDetailsNewContext();
  const paymentDateField = watch(PAYMENT_DATE_FIELD);
  const referenceNumberField = watch(PAYMENT_REFERENCE_NUMBER);
  const notNullFields = paymentDateField || referenceNumberField;
  const snackbar = useSnackbar();

  const [handlePartialUpdateInvoice, { isLoading: isLoadingPartialUpdateInvoice }] =
    useApiInvoicesUpdatePartialUpdateMutation();

  const handleAddPaymentNumber = async () => {
    await NiceModal.show(AddNumberModalId, {
      title: t('dashboard.paymentDetailsFilled'),
      helperText: t('dashboard.addPaymentNumberModal.helperText'),
      label: t('dashboard.addPaymentNumberModal.label'),
      setNumber: setReferenceNumber,
      mutationIsLoading: isLoadingPartialUpdateInvoice,
      updateMutation: async (ref_number: string) => {
        try {
          const res = await handlePartialUpdateInvoice({
            id: +invoiceId,
            patchedInvoiceUpdateRequest: {
              ...(ref_number && { paid_reference_number: ref_number }),
              ...(ref_number && !invoiceData?.is_paid && { is_paid: true }),
            },
          });
          if (res) {
            toggleEdit(editPayment, setEditPayment);
          }
        } catch (err) {
          snackbar.enqueueSnackbar(getErrorMessage(err, API_ERROR_MSG_PATH), { variant: ERROR });
        }
      },
    });
  };

  const handleAddPaymentDate = async () => {
    await NiceModal.show(AddDateModalId, {
      title: t('dashboard.paymentDateFilled'),
      label: t('dashboard.addPaymentDateModal.label'),
      setDate: setPaymentDate,
      mutationIsLoading: isLoadingPartialUpdateInvoice,
      updateMutation: async (isoDate: string) => {
        try {
          await handlePartialUpdateInvoice({
            id: +invoiceId,
            patchedInvoiceUpdateRequest: {
              ...(isoDate && { paid_date: isoDate }),
              ...(isoDate && !invoiceData?.is_paid && { is_paid: true }),
            },
          });
        } catch (err) {
          snackbar.enqueueSnackbar(getErrorMessage(err, API_ERROR_MSG_PATH), { variant: ERROR });
        }
      },
    });
  };

  return (
    <Box display="flex" flexDirection="column" gap={3}>
      <Typography variant="h6" fontWeight={500} fontFamily="WFVisualSans">
        {t('dashboard.paymentDetailsBlock.title')}
      </Typography>
      {!editPayment && notNullFields && (
        <StyledEditSectionButton
          variant="outlined"
          size="small"
          onClick={() => {
            toggleEdit(editPayment, setEditPayment);
            handleResetForm();
          }}
          endIcon={<EditIcon />}
        >
          {t('common.editPaymentDetails')}
        </StyledEditSectionButton>
      )}
      <Box display="flex" gap={3.5} m={0}>
        {!paymentDateField && !paymentDate && (
          <AddDetailsButton onClick={handleAddPaymentDate} text={t('dashboard.paymentDetailsBlock.addDate')} />
        )}
        {!referenceNumberField && !referenceNumber && (
          <AddDetailsButton onClick={handleAddPaymentNumber} text={t('dashboard.paymentDetailsBlock.addNumber')} />
        )}
        {paymentDateField && !editPayment && (
          <Box display="flex" flexDirection="column" gap={0.5} justifyContent="center">
            <StyledTitle variant="subtitle2">{t('dashboard.paymentDateFilled')}</StyledTitle>
            <Typography variant="subtitle1" fontWeight={400}>
              {convertIsoToReadable(paymentDateField, { fullYear: true })}
            </Typography>
          </Box>
        )}
        {referenceNumberField && !editPayment && (
          <Box display="flex" flexDirection="column" gap={0.5} justifyContent="center">
            <StyledTitle variant="subtitle2">{t('dashboard.paymentDetailsFilled')}</StyledTitle>
            <Typography variant="subtitle1" fontWeight={400}>
              {referenceNumberField}
            </Typography>
          </Box>
        )}
      </Box>
      {editPayment && <PaymentDetailsEdit loading={loading} />}
    </Box>
  );
};

export default memo(PaymentDetails);
