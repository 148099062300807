import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  TextField,
  TextFieldProps,
  Typography,
  TypographyProps,
} from '@mui/material';
import { styled, Theme } from '@mui/material/styles';
import { DataGrid, DataGridProps } from '@mui/x-data-grid';
import LoadingButton from '@components/LoadingButton';

export const StyledDocViewerWrapper = styled('div')`
  #pdf-controls {
    display: none;
  }
  #image-renderer {
    background-image: none;
  }
`;

export const StyledControlsBox = styled(Box)<BoxProps & { theme?: Theme }>(({ theme }) => ({
  padding: '8px 10px',
  backgroundColor: theme.colors.background.secondaryBackground,
}));

export const StyledFiltersButton = styled(Button)<ButtonProps>({
  boxShadow: 'none',
});

export const StyledTextField = styled(TextField)<TextFieldProps>({
  margin: '10px 0',
  '& .MuiInputLabel-root:not(.MuiInputLabel-shrink)': {
    transform: 'translate(43px, 17px)',
  },
});

export const StyledDataGrid = styled(DataGrid)<DataGridProps & { theme?: Theme }>(() => ({
  '& .MuiDataGrid-footerContainer': {
    border: 'transparent',
    justifyContent: 'start',
  },
  '& .MuiDataGrid-columnSeparator': {
    display: 'none',
  },
  '& .MuiDataGrid-columnHeader:focus': {
    outline: 'none',
  },
  '& .MuiDataGrid-columnHeader:focus-within': {
    outline: 'none',
  },
}));

export const StyledClaimTypography = styled(Typography)<TypographyProps & { theme?: Theme; claimed?: boolean }>(
  ({ theme, claimed = false }) => ({
    fontWeight: 700,
    fontSize: '14px',
    letterSpacing: '2px',
    color: claimed ? theme.colors.primary.main : theme.colors.warning.main,
  }),
);

export const StyledLoadingButton = styled(LoadingButton)<{ theme?: Theme }>(({ theme }) => ({
  '& .MuiCircularProgress-root': {
    color: theme.colors.primary.main,
  },
  boxShadow: 'none',
}));
