import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ROUTING } from '@constants/routing';
import { useSubscription } from '@hooks/useSubscription';
import { NAV } from '@providers/layouts/AppLayout/config';
import { StyledSubscribeBox, StyledTextOverflowBox } from '@providers/layouts/AppLayout/styled';

import { Typography } from '@mui/material';
import Box from '@mui/material/Box';

const TrialSection: FC<{ expanded: boolean }> = ({ expanded }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data, isTrial } = useSubscription();
  const isOnTrial = data?.is_trial && !!data?.trial_days_left;
  const daysLeft = isOnTrial ? data?.trial_days_left : data?.days_left;

  const handleSubscribeRedirect = () => navigate(`/${ROUTING.SUBSCRIBE}`);

  return (
    <StyledTextOverflowBox width={expanded ? NAV.WIDTH - NAV.COLLAPSED_WIDTH : NAV.COLLAPSED_WIDTH} overflow="hidden">
      <Box
        display="flex"
        px={2}
        py={1}
        alignItems="baseline"
        gap={0.5}
        style={{ backgroundColor: 'rgba(239, 232, 247, 1)', borderRadius: '4px', textAlign: 'start' }}
      >
        <Trans
          i18nKey={isOnTrial ? t('dashboard.trialDaysLeft') : t('dashboard.paidDaysLeft')}
          values={{
            daysLeft: daysLeft ?? 0,
          }}
          components={{
            strong: <Typography component="span" fontSize={16} fontWeight={600} />,
            regular: <Typography component="span" fontSize={14} fontWeight={400} />,
          }}
        />
      </Box>
      {isTrial && (
        <StyledSubscribeBox pl={1} pt={1} onClick={handleSubscribeRedirect}>
          <Typography fontSize={13} fontWeight={500}>
            {t('auth.subscribe')}
          </Typography>
        </StyledSubscribeBox>
      )}
    </StyledTextOverflowBox>
  );
};

export default TrialSection;
