import { useTranslation } from 'react-i18next';
import { InvoiceRead } from '@api/api';
import { NOT_AVAILABLE } from '@constants/common';
import { StyledClaimTypography } from '@pages/Invoices/styled';
import { formatCurrency } from '@utils/formatCurrency';
import { convertIsoToReadable } from '@utils/formatTime';

import WarningIcon from '@mui/icons-material/Warning';
import { Box, Typography } from '@mui/material';
import { GridCellParams, GridColDef } from '@mui/x-data-grid';
import { InvoiceClaimed, InvoiceNotClaimed } from '@components/icons';
import OverflowTip from '@components/OverflowTip';

export const useListColumns = (): GridColDef[] => {
  const { t } = useTranslation();

  return [
    {
      field: 'due_date',
      headerName: 'SUPPORT DATE',
      hideable: false,
      sortable: true,
      resizable: false,
      headerAlign: 'center',
      width: 160,
      renderCell: ({ row }: GridCellParams<InvoiceRead>) => (
        <OverflowTip>
          <Box display="flex" gap={1} alignItems="center" ml={1}>
            {row.is_claimed ? <InvoiceClaimed /> : <InvoiceNotClaimed />}
            <Typography variant="body2">
              {row.due_date && row.due_date.length > 0
                ? convertIsoToReadable(row.due_date, { fullYear: true })
                : NOT_AVAILABLE}
            </Typography>
          </Box>
        </OverflowTip>
      ),
    },
    {
      field: 'vendor_name',
      headerName: 'PROVIDER',
      hideable: false,
      sortable: true,
      resizable: false,
      flex: 1,
      maxWidth: 550,
      renderCell: ({ row }: GridCellParams<InvoiceRead>) => (
        <OverflowTip>
          <Typography variant="body2" component="span">
            {row.vendor_name}
          </Typography>
        </OverflowTip>
      ),
    },
    {
      field: 'total_amount',
      headerName: 'AMOUNT',
      hideable: false,
      sortable: true,
      resizable: false,
      width: 140,
      renderCell: ({ row }: GridCellParams<InvoiceRead>) => (
        <OverflowTip>
          <Typography variant="body2">
            {`${row.currency_symbol ? row.currency_symbol : ''}${formatCurrency(row.total_amount)}`}
          </Typography>
        </OverflowTip>
      ),
    },
    {
      field: 'description',
      headerName: 'DESCRIPTION',
      hideable: false,
      sortable: false,
      resizable: false,
      // minWidth: 380,
      flex: 1,
      renderCell: ({ row }: GridCellParams<InvoiceRead>) => (
        <OverflowTip>
          <Typography variant="body2" mr={9} textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap">
            {row.description || t('common.none')}
          </Typography>
        </OverflowTip>
      ),
    },
    {
      field: 'category',
      headerName: 'SUPPORT CATEGORY',
      hideable: false,
      sortable: true,
      resizable: false,
      maxWidth: 550,
      flex: 1,
      renderCell: ({ row }: GridCellParams<InvoiceRead>) => (
        <OverflowTip>
          {!row.category && (
            <Box display="flex" alignItems="center" gap={1} mr={5}>
              <WarningIcon color="warning" />
              <Typography variant="body2">Not yet selected</Typography>
            </Box>
          )}
          {row.category && (
            <Typography mr={5} variant="body2" textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap">
              {row.category.name}
            </Typography>
          )}
        </OverflowTip>
      ),
    },
    {
      field: 'is_claimed',
      headerName: 'STATUS',
      hideable: false,
      sortable: true,
      resizable: false,
      flex: 1,
      width: 150,
      renderCell: ({ row }: GridCellParams<InvoiceRead>) => (
        <OverflowTip>
          <StyledClaimTypography variant="body2" component="span" claimed={row.is_claimed}>
            {row.is_claimed ? t('common.invoice.claimed') : t('common.invoice.notClaimed')}
          </StyledClaimTypography>
        </OverflowTip>
      ),
    },
  ];
};
