export const VENDOR_NAME_FIELD = 'vendor_name';
export const SERVICE_START_DATE_FIELD = 'service_start_date';
export const SERVICE_END_DATE_FIELD = 'service_end_date';
export const SERVICE_EXACT_DATE_FIELD = 'service_exact_date';
export const ABN_FIELD = 'abn';
export const TOTAL_AMOUNT_FIELD = 'total_amount';
export const DESCRIPTION_FIELD = 'description';
export const CATEGORY_FIELD = 'category';
export const MORE_THAN_ONE_DAY_FIELD = 'support_more_date'; // checkbox
export const MISSING_REASON_FIELD = 'missing_reason';
export const MISSING_CATEGORY_FIELD = 'missing_category';
export const HAS_ABN_FIELD = 'has_abn';
export const PAYMENT_DATE_FIELD = 'paid_date';
export const PAYMENT_REFERENCE_NUMBER = 'paid_reference_number';
export const CLAIM_DATE_FIELD = 'claimed_date';
export const CLAIM_ID_FIELD = 'claimed_reference_number';
export const MISSING_DESCRIPTION_FIELD = 'missing_description';
export const MISSING_VENDOR_NAME_FIELD = 'missing_vendor_name';
export const MISSING_MORE_THAN_ONE_DAY_FIELD = 'missing_support_more_date';
export const MISSING_SERVICE_START_DATE_FIELD = 'missing_service_start_date';
export const MISSING_SERVICE_END_DATE_FIELD = 'missing_service_end_date';
export const MISSING_SERVICE_EXACT_DATE_FIELD = 'missing_service_exact_date';
