import { Link, LinkProps } from 'react-router-dom';

import { Box, BoxProps } from '@mui/material';
import { styled, Theme } from '@mui/material/styles';

export const StyledFooterBox = styled(Box)<BoxProps & { theme?: Theme }>(({ theme }) => ({
  backgroundColor: theme.colors.secondary.contrast,
  padding: '10px 40px',
  color: theme.colors.white,
  position: 'relative',
  // minWidth: '1728px',
}));

export const StyledLink = styled(Link)<LinkProps & { component?: typeof Link }>(({ theme }) => ({
  color: theme.colors.white,
  textDecoration: 'underline',
  textDecorationColor: theme.colors.underline,
}));
