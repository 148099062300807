import { createContext, FC, PropsWithChildren, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  useApiCategoriesListQuery,
  useApiInvoicesRetrieveQuery,
  useApiInvoicesUpdatePartialUpdateMutation,
  useApiReasonsListQuery,
} from '@api/api';
import { yupResolver } from '@hookform/resolvers/yup';
import useDownloadFile from '@hooks/api/useDownloadFile';
import { useRevert } from '@hooks/api/useRevert';
import { DEFAULT_VALUES, MISSING_DETAILS_DEFAULTS } from '@pages/InvoiceDetailsNew/constants/defaultValues';
import { claimSchema, detailsSchema, paymentSchema, updateInvoiceSchema } from '@pages/InvoiceDetailsNew/schema';
import { ClaimSchema, DetailsSchema, PaymentSchema, UpdateSchema } from '@pages/InvoiceDetailsNew/schema/types';
import { findCategoryOption, findReasonOption } from '@pages/InvoiceDetailsNew/utils';
import { formatCurrency } from '@utils/formatCurrency';

const InvoiceDetailsNewContext = createContext<ReturnType<typeof useInvoiceDetailsNewContextValue> | null>(null);

const useInvoiceDetailsNewContextValue = () => {
  const [editInvoice, setEditInvoice] = useState(false);
  const [editClaim, setEditClaim] = useState(false);
  const [editPayment, setEditPayment] = useState(false);
  const { invoiceId = '1' } = useParams();
  const { revertField, isLoading } = useRevert(+invoiceId);
  const { t } = useTranslation();
  const { fileBlob, fileName, isLoadingDownload, isLoadingPreview, downloadPreview, fileType } = useDownloadFile();

  const { data: invoiceData, isLoading: isLoadingInvoices } = useApiInvoicesRetrieveQuery({
    id: invoiceId ? +invoiceId : 1,
  });
  const { data: categoriesOptions, isLoading: isLoadingCategoryOptions } = useApiCategoriesListQuery();
  const [invoiceUpdateMutation, { isLoading: isLoadingUpdate }] = useApiInvoicesUpdatePartialUpdateMutation();
  const { data: reasonOptions, isLoading: isLoadingReasons } = useApiReasonsListQuery();

  // ToDO after api update set these to true if invoiceData has corresponding values
  const [isPaid, setIsPaid] = useState(false);
  const [isClaimed, setIsClaimed] = useState(false);
  const [referenceNumber, setReferenceNumber] = useState<string | boolean | null>(null);
  const [paymentDate, setPaymentDate] = useState<string | boolean | null>(null);
  const [claimIdNumber, setClaimIdNumber] = useState<string | boolean | null>(null);
  const [claimDate, setClaimDate] = useState<string | boolean | null>(null);

  const serviceDescription = invoiceData?.description;
  const providerAbnNumber = invoiceData?.abn || t('common.none');
  const supportCategory = invoiceData?.category;
  const descriptionLength = serviceDescription?.length;
  const isReason = invoiceData?.reason?.id;

  const invoiceForm = useForm<UpdateSchema>({
    // @ts-ignore
    resolver: yupResolver(updateInvoiceSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: DEFAULT_VALUES,
  });

  const detailsForm = useForm<DetailsSchema>({
    // @ts-ignore
    resolver: yupResolver(detailsSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: MISSING_DETAILS_DEFAULTS,
  });

  const paymentDetailsForm = useForm<PaymentSchema>({
    // @ts-ignore
    resolver: yupResolver(paymentSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      paid_reference_number: undefined,
      paid_date: undefined,
    },
  });

  const claimDetailsForm = useForm<ClaimSchema>({
    // @ts-ignore
    resolver: yupResolver(claimSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const toggleEdit = useCallback((val: boolean, setVal: (val: boolean) => void) => setVal(!val), []);

  const handleResetForm = useCallback(() => {
    if (invoiceData) {
      invoiceForm.reset({
        support_more_date: !!(invoiceData.service_start_date && invoiceData.service_end_date), // prefill checkbox
        description: serviceDescription ?? '',
        recipient_email: invoiceData.recipient_email ?? '',
        vendor_email: invoiceData.vendor_email ?? '',
        vendor_name: invoiceData.vendor_name ?? '',
        invoice_date: invoiceData.invoice_date ? new Date(invoiceData.invoice_date) : undefined,
        due_date: invoiceData.due_date ? new Date(invoiceData.due_date) : undefined,
        updated_at: invoiceData.updated_at ? new Date(invoiceData.updated_at) : undefined,
        service_start_date: invoiceData.service_start_date ? new Date(invoiceData.service_start_date) : undefined,
        service_end_date: invoiceData.service_end_date ? new Date(invoiceData.service_end_date) : undefined,
        service_exact_date: invoiceData.service_exact_date ? new Date(invoiceData.service_exact_date) : undefined,
        customer_name: invoiceData.customer_name ?? '',
        customer_address: invoiceData.customer_address ?? '',
        abn: invoiceData.abn || '',
        has_abn: !!invoiceData.abn,
        sub_total: invoiceData.sub_total ?? '',
        total_tax: invoiceData.total_tax ?? '',
        total_amount: formatCurrency(invoiceData.total_amount) ?? '',
        currency_symbol: invoiceData.currency_symbol || '$',
        currency: invoiceData.currency || 'AUD',
        category:
          categoriesOptions && invoiceData.category
            ? findCategoryOption({ id: invoiceData.category.id, name: invoiceData.category.name }, categoriesOptions)
            : undefined,
        missing_reason:
          reasonOptions && invoiceData.reason
            ? findReasonOption({ id: invoiceData.reason.id, name: invoiceData.reason.name }, reasonOptions)
            : undefined,
      });

      paymentDetailsForm.reset({
        paid_date: invoiceData.paid_date ? new Date(invoiceData.paid_date) : undefined,
        paid_reference_number: invoiceData.paid_reference_number ? invoiceData.paid_reference_number : undefined,
      });

      claimDetailsForm.reset({
        claimed_date: invoiceData.claimed_date ? new Date(invoiceData.claimed_date) : undefined,
        claimed_reference_number: invoiceData.claimed_reference_number
          ? invoiceData.claimed_reference_number
          : undefined,
      });
    }
  }, [
    categoriesOptions,
    claimDetailsForm,
    invoiceData,
    invoiceForm,
    paymentDetailsForm,
    reasonOptions,
    serviceDescription,
  ]);

  useEffect(() => {
    if (invoiceData) {
      setIsPaid(!!invoiceData.is_paid);
      setIsClaimed(!!invoiceData.is_claimed);
      setPaymentDate(!!invoiceData.paid_date);
      setClaimDate(!!invoiceData.claimed_date);
      setReferenceNumber(!!invoiceData.paid_reference_number);
      setClaimIdNumber(!!invoiceData.claimed_reference_number);
    }
  }, [invoiceData, invoiceId]);

  const downloadPreviewCalledRef = useRef(false);

  useEffect(() => {
    if (!downloadPreviewCalledRef.current && invoiceId) {
      downloadPreview({ id: +invoiceId });
      downloadPreviewCalledRef.current = true;
    }
  }, [downloadPreview, invoiceId]);

  return {
    invoiceForm,
    editInvoice,
    setEditInvoice,
    toggleEdit,
    invoiceId,
    isLoadingRevert: isLoading,
    revertField,
    invoiceData,
    isLoadingInvoices,
    isPaid,
    setIsPaid,
    isClaimed,
    setIsClaimed,
    handleResetForm,
    isLoadingCategoryOptions,
    serviceDescription,
    categoriesOptions,
    detailsForm,
    invoiceUpdateMutation,
    isLoadingUpdate,
    providerAbnNumber,
    supportCategory,
    descriptionLength,
    paymentDetailsForm,
    claimDetailsForm,
    referenceNumber,
    setReferenceNumber,
    paymentDate,
    setPaymentDate,
    claimIdNumber,
    setClaimIdNumber,
    claimDate,
    setClaimDate,
    editClaim,
    setEditClaim,
    editPayment,
    setEditPayment,
    isLoadingReasons,
    reasonOptions,
    isReason,
    fileBlob,
    fileName,
    downloadPreview,
    isLoadingDownload,
    fileType,
    isLoadingPreview,
  };
};

export const useInvoiceDetailsNewContext = () => {
  const context = useContext(InvoiceDetailsNewContext);
  if (!context) throw new Error('useInvoiceDetailsNewContext must be inside InvoiceDetailsNewProvider');
  return context;
};

export const InvoiceDetailsNewProvider: FC<PropsWithChildren> = ({ children }) => {
  const value = useInvoiceDetailsNewContextValue();
  return <InvoiceDetailsNewContext.Provider value={value}>{children}</InvoiceDetailsNewContext.Provider>;
};
