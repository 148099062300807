import { FC, memo, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PAGE_HEADER_RAW_HEIGHT } from '@constants/common';
import EditableColumn from '@pages/InvoiceDetailsNew/components/EditableColumn';
import InfoColumn from '@pages/InvoiceDetailsNew/components/InfoColumn';
import PreviewColumn from '@pages/InvoiceDetailsNew/components/PreviewColumn';
import { useInvoiceDetailsNewContext } from '@pages/InvoiceDetailsNew/context';
import { StyledEditableColumnGrid, StyledInfoColumnGrid } from '@pages/InvoiceDetailsNew/styled';

import { Grid, LinearProgress } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const InvoiceDetailsNew: FC = () => {
  const { isLoadingInvoices, invoiceData, isLoadingCategoryOptions, handleResetForm, isLoadingReasons, invoiceForm } =
    useInvoiceDetailsNewContext();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const theme = useTheme();
  const isXl = useMediaQuery(theme.breakpoints.up('lg'));

  const isLoading = [isLoadingInvoices, isLoadingCategoryOptions, isLoadingReasons].some(Boolean);

  useEffect(() => {
    if (invoiceData) {
      handleResetForm();
    }
    invoiceForm.trigger();
  }, [handleResetForm, invoiceData, invoiceForm, navigate]);

  if (isLoading) {
    return <LinearProgress />;
  }

  return (
    <>
      <Helmet>
        <title>{t('common.helmetTitles.invoiceDetails')}</title>
      </Helmet>

      <Grid container columns={12} height={`calc(100% - ${PAGE_HEADER_RAW_HEIGHT}px)`} width="auto">
        <StyledInfoColumnGrid
          item
          md={12}
          lg={3}
          display="flex"
          flexDirection="column"
          gap={3}
          p={isXl ? '41px 40px 23px 40px' : '41px 30px 23px 30px'}
        >
          <InfoColumn data={invoiceData} />
        </StyledInfoColumnGrid>
        <StyledEditableColumnGrid
          item
          md={12}
          lg={5}
          display="flex"
          flexDirection="column"
          gap={2}
          p={isXl ? '41px 50px' : '41px 35px'}
        >
          <EditableColumn />
        </StyledEditableColumnGrid>
        <Grid
          item
          md={12}
          lg={4}
          style={{ backgroundColor: '#fcfcfc', borderRadius: '4px' }}
          p={isXl ? '20px' : '20px 10px'}
        >
          <PreviewColumn />
        </Grid>
      </Grid>
    </>
  );
};

export default memo(InvoiceDetailsNew);
