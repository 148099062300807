import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useUser } from '@hooks/api/useUser';
import { NAV } from '@providers/layouts/AppLayout/config';
import { StyledNavigationIdBox, StyledTextOverflowBox } from '@providers/layouts/AppLayout/styled';

import { Typography } from '@mui/material';
import Box from '@mui/material/Box';

const UserId: FC<{ expanded: boolean }> = ({ expanded }) => {
  const { data } = useUser();
  const { t } = useTranslation();

  return (
    <StyledTextOverflowBox
      display="flex"
      gap={1}
      flexDirection="column"
      width={expanded ? NAV.WIDTH - NAV.COLLAPSED_WIDTH : NAV.COLLAPSED_WIDTH}
      overflow="hidden"
    >
      <Box display="flex" gap={1} alignItems="center">
        <Typography fontWeight={400} fontSize={14}>
          {t('dashboard.navigation.user.capsureIdTitle')}
        </Typography>
      </Box>
      <StyledNavigationIdBox display="flex" alignItems="center" justifyContent="center">
        <Typography variant="body2" overflow="hidden" textOverflow="ellipsis">
          {data?.invoice_email || ''}
        </Typography>
      </StyledNavigationIdBox>
    </StyledTextOverflowBox>
  );
};

export default memo(UserId);
