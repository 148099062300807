import { ChangeEvent, FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { InvoiceRead, useApiInvoicesDestroyMutation, useApiInvoicesUpdatePartialUpdateMutation } from '@api/api';
import { ERROR } from '@constants/auth';
import { API_ERROR_MSG_PATH } from '@constants/common';
import { ROUTING } from '@constants/routing';
import NiceModal from '@ebay/nice-modal-react';
import { StyledFormControlLabel, StyledTextButton } from '@pages/InvoiceDetailsNew/components/InfoColumn/styled';
import { useInvoiceDetailsNewContext } from '@pages/InvoiceDetailsNew/context';
import { useHandleCheckboxes } from '@pages/InvoiceDetailsNew/hooks/useHandleCheckboxes';
import { UndoModalId } from '@pages/InvoiceDetailsNew/modals/UndoModal';
import { StyledLink } from '@pages/styled';
import { formatCurrency } from '@utils/formatCurrency';
import { convertIsoToReadableWithTime } from '@utils/formatTime';
import { getErrorMessage } from '@utils/getMessage';
import { useSnackbar } from 'notistack';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Checkbox, CircularProgress, Typography } from '@mui/material';
import CredentialDetails from '@components/CredentialDetails';
import StatusChip from '@components/StatusChip';

const InfoColumn: FC<{ data?: InvoiceRead }> = ({ data }) => {
  const { t } = useTranslation();
  const {
    isPaid,
    setIsPaid,
    isClaimed,
    setIsClaimed,
    serviceDescription,
    setEditClaim,
    setEditPayment,
    setReferenceNumber,
    setPaymentDate,
    setClaimIdNumber,
    setClaimDate,
    invoiceId,
  } = useInvoiceDetailsNewContext();
  const { handleStateChange } = useHandleCheckboxes();
  const snackbar = useSnackbar();
  const navigate = useNavigate();
  const [handleDestroyInvoice, { isLoading: isLoadingDestroyInvoice }] = useApiInvoicesDestroyMutation();
  const [invoicePartialUpdateMutation] = useApiInvoicesUpdatePartialUpdateMutation();

  const handlePaidChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const result = await handleStateChange(e, isPaid, setIsPaid, {
      title: t('dashboard.undoModal.paid.title'),
      text: t('dashboard.undoModal.paid.text'),
      buttonText: t('dashboard.undoModal.paid.buttonText'),
    });

    if (!isPaid) {
      await invoicePartialUpdateMutation({
        id: +invoiceId,
        patchedInvoiceUpdateRequest: {
          is_paid: true,
        },
      });
      return;
    }

    if (result && isPaid) {
      await invoicePartialUpdateMutation({
        id: +invoiceId,
        patchedInvoiceUpdateRequest: {
          is_paid: false,
          paid_date: null,
          paid_reference_number: null,
        },
      });
      setEditPayment(false);
      setReferenceNumber(null);
      setPaymentDate(null);
    }
  };

  const handleClaimChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const result = await handleStateChange(e, isClaimed, setIsClaimed, {
      title: t('dashboard.undoModal.claimed.title'),
      text: t('dashboard.undoModal.claimed.text'),
      buttonText: t('dashboard.undoModal.claimed.buttonText'),
    });

    if (!isClaimed) {
      await invoicePartialUpdateMutation({
        id: +invoiceId,
        patchedInvoiceUpdateRequest: {
          is_claimed: true,
        },
      });
    }

    if (result && isClaimed) {
      await invoicePartialUpdateMutation({
        id: +invoiceId,
        patchedInvoiceUpdateRequest: {
          is_claimed: false,
          claimed_date: null,
          claimed_reference_number: null,
          claimed_origin: null,
        },
      });
      setEditClaim(false);
      setClaimIdNumber(null);
      setClaimDate(null);
    }
  };

  const handleDeleteInvoice = async () => {
    try {
      const result = await NiceModal.show(UndoModalId, {
        title: t('dashboard.undoModal.deleteInvoice.title'),
        text: t('dashboard.undoModal.deleteInvoice.text'),
        buttonText: t('dashboard.undoModal.deleteInvoice.buttonText'),
      });

      if (result) {
        await handleDestroyInvoice({ id: +invoiceId });
        navigate(ROUTING.ROOT, { replace: true });
      }
    } catch (err) {
      snackbar.enqueueSnackbar(getErrorMessage(err, API_ERROR_MSG_PATH), { variant: ERROR });
    }
  };

  const originField = data?.is_manual_upload
    ? t('dashboard.invoiceDetails.manualUpload')
    : t('dashboard.invoiceDetails.emailUpload');
  const receivedField = convertIsoToReadableWithTime(data?.created_at) || '';

  return (
    <Box display="flex" flexDirection="column" gap={3} height="100%">
      <StyledLink
        component={RouterLink}
        to={`${ROUTING.ROOT}`}
        replace
        aria-label="back button"
        sx={{ height: '26px', margin: 0 }}
      >
        <Box display="flex" alignItems="center" gap={0.5} ml="-4px">
          <ChevronLeftIcon />{' '}
          <Typography fontSize={16} fontWeight={500}>
            {t('common.back')}
          </Typography>
        </Box>
      </StyledLink>

      <Box display="flex" flexDirection="column" gap={2}>
        <Box>
          <Typography variant="h6" fontWeight={500} fontFamily="WFVisualSans">
            {data?.vendor_name || ''}
          </Typography>
          <Typography
            fontWeight={400}
            style={{
              wordWrap: 'break-word',
              whiteSpace: 'normal',
            }}
          >
            {serviceDescription}
          </Typography>
          <Typography
            mt={2}
            fontWeight={400}
          >{`${data?.currency_symbol}${formatCurrency(data?.total_amount)}`}</Typography>
        </Box>

        <Box mt={2.5}>
          <Box display="flex" gap={2.5} flexWrap="wrap">
            <StatusChip
              locale={
                isClaimed || data?.is_claimed
                  ? t('dashboard.invoiceDetails.claimed')
                  : t('dashboard.invoiceDetails.notClaimed')
              }
              isModified={isClaimed || data?.is_claimed}
            />
            <StatusChip
              locale={
                isPaid || data?.is_paid ? t('dashboard.invoiceDetails.paid') : t('dashboard.invoiceDetails.notPaid')
              }
              isModified={isPaid || data?.is_paid}
            />
          </Box>
        </Box>

        <Box>
          <StyledFormControlLabel
            control={<Checkbox color="primary" checked={isClaimed} onChange={handleClaimChange} />}
            labelPlacement="end"
            label={t('dashboard.invoiceDetails.markClaimed')}
          />
          <StyledFormControlLabel
            control={<Checkbox color="primary" checked={isPaid} onChange={handlePaidChange} />}
            labelPlacement="end"
            label={t('dashboard.invoiceDetails.markPaid')}
          />
        </Box>
      </Box>

      <Box display="flex" flexDirection="column" gap={2} flexGrow={1}>
        <Box display="flex" flexWrap="wrap" gap={3}>
          <CredentialDetails title={t('dashboard.invoiceDetails.origin')} value={originField} />
          <CredentialDetails title={t('dashboard.invoiceDetails.received')} value={receivedField} />
        </Box>
        <CredentialDetails
          title={t('dashboard.invoiceDetails.sender')}
          value={data?.vendor_email || t('common.none')}
        />
      </Box>

      {!isLoadingDestroyInvoice && (
        <StyledTextButton
          disableRipple
          disableElevation
          disableTouchRipple
          disableFocusRipple
          variant="text"
          onClick={handleDeleteInvoice}
          endIcon={<DeleteIcon color="primary" />}
          color="primary"
          maxWidth="100px"
          sx={{ marginBottom: '-10px' }}
        >
          {t('common.delete')}
        </StyledTextButton>
      )}
      {/* ToDo possibly need to rethink disabling UI while delete in process */}
      {isLoadingDestroyInvoice && <CircularProgress size={30} color="primary" />}
    </Box>
  );
};

export default memo(InfoColumn);
