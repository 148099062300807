import {
  AccordionDetailsProps,
  AccordionProps,
  AccordionSummaryProps,
  Box,
  BoxProps,
  Button,
  ButtonProps,
  Paper,
  PaperProps,
  Typography,
  TypographyProps,
} from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { styled } from '@mui/material/styles';

export const StyledTypographyContrast = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.colors.secondary.contrast,
}));

export const StyledTypographyPrimaryDark = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.colors.primary.dark,
}));

export const StyledTypographyPrimaryMain = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.colors.primary.main,
}));

export const StyledPrimaryBox = styled(Box)<BoxProps & { isSelected?: boolean }>(({ theme, isSelected }) => ({
  backgroundColor: isSelected ? theme.colors.primary.main : theme.colors.background.secondaryBackground,
  color: isSelected ? 'white' : theme.colors.primary.main,
  borderRadius: isSelected ? 'none' : '5px 5px 0px 0px',
  borderBottom: `1px solid ${theme.colors.primary.main}`,
}));

export const CardContainer = styled(Paper)<PaperProps & { elevation: number; isSelected: boolean }>(
  ({ theme, elevation, isSelected }) => ({
    width: '100%',
    border: `${isSelected ? '2px' : '1px'} solid ${theme.colors.primary.main}`,
    borderRadius: '5px',
    boxShadow: elevation ? `${theme.shadows[elevation]}` : 'none',
  }),
);

export const StyledSummaryBox = styled(Box)<BoxProps>(({ theme }) => ({
  backgroundColor: theme.colors.secondary.contrast,
  color: theme.colors.white,
}));

export const StyledFloatingBox = styled(Box)<BoxProps>(({ theme }) => ({
  position: 'absolute',
  width: '100%',
  height: '470px',
  backgroundColor: theme.colors.background.secondaryBackground,
}));

export const StyledAccordionSummary = styled(AccordionSummary)<AccordionSummaryProps>(() => ({
  display: 'flex',
  '& .MuiAccordionSummary-content': {
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginRight: '5px',
  },
  '& .MuiAccordionSummary-content.Mui-expanded': {
    marginRight: '5px',
  },
}));

export const StyledAccordion = styled(Accordion)<AccordionProps>(({ theme }) => ({
  backgroundColor: theme.colors.background.secondaryBackground,
  borderRadius: '5px',
  border: `1px solid ${theme.colors.primary.main}`,
  padding: '10px',
  maxWidth: '900px',
}));

export const StyledAccordionDetails = styled(AccordionDetails)<AccordionDetailsProps>(({ theme }) => ({
  color: theme.colors.secondary.contrast,
  display: 'flex',
  flexDirection: 'column',
  gap: '18px',
  maxWidth: '880px',
}));

export const StyledLogoutButton = styled(Button)<ButtonProps>({
  boxShadow: 'none',
  minWidth: '130px',
});
