import { ClipboardEvent, Dispatch, FC, memo, SetStateAction, useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CONFIRM_EMAIL_NUMBER_OF_DIGITS } from '@constants/auth';
import Countdown from '@pages/ConfirmEmail/components/Countdown';
import { StyledTitle } from '@pages/Signup/components/styled';
import { StyledBox, StyledPaper, StyledTextField } from '@pages/styled';

import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Typography } from '@mui/material';

import { StyledLink } from './styled';

const ConfirmEmailForm: FC<{
  loading: boolean;
  handleResend: () => void;
  isCodeSent: boolean;
  countdown: number;
  setCodeSent: Dispatch<SetStateAction<boolean>>;
  setCountdown: Dispatch<SetStateAction<number>>;
  myEmail?: string;
}> = ({ loading, handleResend, isCodeSent, countdown, setCountdown, setCodeSent, myEmail }) => {
  const { t } = useTranslation();
  const {
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext();
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  const handleCodeChange = (index: number, value: string) => {
    const singleDigit = value.slice(-1);
    const currentValues = getValues('code') || [];

    currentValues[index] = singleDigit;
    setValue(`code[${index}]`, singleDigit, { shouldValidate: true });

    if (singleDigit && index < CONFIRM_EMAIL_NUMBER_OF_DIGITS - 1) {
      inputRefs.current[index + 1]?.focus();
    }

    if (singleDigit === '' && index > 0) {
      inputRefs.current[index - 1]?.focus();
    }
  };

  const handlePaste = (e: ClipboardEvent<HTMLInputElement | HTMLTextAreaElement | undefined>) => {
    const pasteData = e.clipboardData.getData('text/plain');
    if (pasteData.length === 6) {
      e.preventDefault();
      pasteData.split('').forEach((digit, idx) => {
        setValue(`code[${idx}]`, digit, { shouldValidate: true });
        if (inputRefs.current[idx]) {
          inputRefs.current[idx].value = digit;
        }
      });
      inputRefs.current[5]?.focus();
    }
  };

  return (
    <Box display="flex" width="100%" justifyContent="center" flexDirection="column" aria-label="Confirm email form">
      <StyledTitle variant="h3" mb={3} maxWidth={400}>
        {t('auth.signupText')}
      </StyledTitle>
      <Typography variant="h5" mb={4} fontFamily="WFVisualSans">
        {t('auth.signupSubtitle')}
      </Typography>
      <StyledPaper elevation={5}>
        <StyledBox gap={3}>
          <Typography variant="h5">{t('auth.confirmEmailTitle')}</Typography>
          <Box>
            <Typography variant="subtitle1">{t('auth.verificationCodeText')}</Typography>
            <Typography variant="body2">{myEmail || ''}</Typography>
          </Box>
          <Typography variant="subtitle1" fontWeight={400}>
            {t('auth.enterCodeBelowText')}
          </Typography>
          <Box width="100%" display="flex" gap={1} mb={1}>
            {[...Array(CONFIRM_EMAIL_NUMBER_OF_DIGITS)].map((_, index) => (
              <Controller
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                control={control}
                name={`code[${index}]`}
                defaultValue=""
                render={({ field: { onChange, ...field } }) => (
                  <StyledTextField
                    {...field}
                    fullWidth
                    type="number"
                    variant="outlined"
                    inputProps={{
                      maxLength: 1,
                      'aria-required': 'true',
                      'aria-label': `verification code field ${index + 1}`,
                      onPaste: handlePaste,
                    }}
                    placeholder="#"
                    onChange={e => {
                      onChange(e);
                      handleCodeChange(index, e.target.value);
                    }}
                    error={Boolean(errors.code)}
                    sx={{ width: 50, '& .MuiInputBase-root input': { textAlign: 'center' } }}
                    inputRef={el => (inputRefs.current[index] = el)}
                  />
                )}
              />
            ))}
          </Box>
          {/* @ts-ignore */}
          {errors?.code?.[0]?.type === 'min' && (
            <Typography color="error" variant="subtitle2">
              {t(`auth.codeLengthError`, { defaultValue: errors.code.message })}
            </Typography>
          )}
          {/* @ts-ignore */}
          {errors.code?.type === 'not_found' && (
            <Typography color="error" variant="subtitle2">
              {t('auth.invalidCode', { defaultValue: errors.code.message })}
            </Typography>
          )}
          <LoadingButton
            fullWidth
            variant="contained"
            color="primary"
            loading={loading}
            type="submit"
            aria-label="confirm button"
          >
            {t('common.verifyAccountBtnText')}
          </LoadingButton>
          <Box display="flex" alignItems="center" justifyContent="center" height="40px" gap={1}>
            <StyledLink
              component="button"
              onClick={handleResend}
              disabled={isCodeSent && !!countdown}
              aria-label="send again button"
            >
              {t('common.sendCodeAgain')}
            </StyledLink>
            {isCodeSent && Number(countdown) > 0 ? (
              <Countdown countdown={countdown} setCountdown={setCountdown} setCodeSent={setCodeSent} />
            ) : null}
          </Box>
        </StyledBox>
      </StyledPaper>
    </Box>
  );
};

export default memo(ConfirmEmailForm);
