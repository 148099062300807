import { FC, memo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useApiInvoicesUpdatePartialUpdateMutation } from '@api/api';
import { ERROR } from '@constants/auth';
import { API_ERROR_MSG_PATH } from '@constants/common';
import NiceModal from '@ebay/nice-modal-react';
import AddDetailsButton from '@pages/InvoiceDetailsNew/components/EditableColumn/components/AddDetailsButton';
import ClaimedDetailsEdit from '@pages/InvoiceDetailsNew/components/EditableColumn/components/ClaimedDetails/ClaimedDetailsEdit';
import { StyledTitle } from '@pages/InvoiceDetailsNew/components/EditableColumn/components/PaymentDetails/styled';
import { StyledEditSectionButton } from '@pages/InvoiceDetailsNew/components/EditableColumn/styled';
import { CLAIM_DATE_FIELD, CLAIM_ID_FIELD } from '@pages/InvoiceDetailsNew/constants';
import { useInvoiceDetailsNewContext } from '@pages/InvoiceDetailsNew/context';
import { AddDateModalId } from '@pages/InvoiceDetailsNew/modals/AddDateModal';
import { AddNumberModalId } from '@pages/InvoiceDetailsNew/modals/AddNumberModal';
import { convertIsoToReadable } from '@utils/formatTime';
import { getErrorMessage } from '@utils/getMessage';
import { useSnackbar } from 'notistack';

import EditIcon from '@mui/icons-material/Edit';
import { Box, Typography } from '@mui/material';

const ClaimedDetails: FC<{ loading?: boolean }> = ({ loading }) => {
  const { t } = useTranslation();
  const { watch } = useFormContext();
  const claimDateField = watch(CLAIM_DATE_FIELD);
  const idNumber = watch(CLAIM_ID_FIELD);
  const notNullFields = idNumber || claimDateField;

  const {
    claimDate,
    claimIdNumber,
    setClaimIdNumber,
    setClaimDate,
    editClaim,
    setEditClaim,
    handleResetForm,
    toggleEdit,
    invoiceId,
    invoiceData,
  } = useInvoiceDetailsNewContext();

  const snackbar = useSnackbar();

  const [handlePartialUpdateInvoice, { isLoading: isLoadingPartialUpdateInvoice }] =
    useApiInvoicesUpdatePartialUpdateMutation();

  const handleAddClaimIdNumber = async () => {
    await NiceModal.show(AddNumberModalId, {
      title: t('dashboard.addClaimIdNumberModal.title'),
      label: t('dashboard.addClaimIdNumberModal.label'),
      setNumber: setClaimIdNumber,
      mutationIsLoading: isLoadingPartialUpdateInvoice,
      updateMutation: async (ref_number: string) => {
        try {
          await handlePartialUpdateInvoice({
            id: +invoiceId,
            patchedInvoiceUpdateRequest: {
              ...(ref_number && { claimed_reference_number: ref_number }),
              ...(ref_number && !invoiceData?.is_claimed && { is_claimed: true }),
              ...(ref_number && !invoiceData?.claimed_origin && { claimed_origin: 'manual_entry' }),
            },
          });
        } catch (err) {
          snackbar.enqueueSnackbar(getErrorMessage(err, API_ERROR_MSG_PATH), { variant: ERROR });
        }
      },
    });
  };

  const handleAddClaimDate = async () => {
    await NiceModal.show(AddDateModalId, {
      title: t('dashboard.addClaimDateModal.title'),
      label: t('dashboard.addClaimDateModal.label'),
      setDate: setClaimDate,
      mutationIsLoading: isLoadingPartialUpdateInvoice,
      updateMutation: async (isoDate: string) => {
        try {
          await handlePartialUpdateInvoice({
            id: +invoiceId,
            patchedInvoiceUpdateRequest: {
              ...(isoDate && { claimed_date: isoDate }),
              ...(isoDate && !invoiceData?.is_claimed && { is_claimed: true }),
              ...(isoDate && !invoiceData?.claimed_origin && { claimed_origin: 'manual_entry' }),
            },
          });
        } catch (err) {
          snackbar.enqueueSnackbar(getErrorMessage(err, API_ERROR_MSG_PATH), { variant: ERROR });
        }
      },
    });
  };

  return (
    <Box display="flex" flexDirection="column" gap={3}>
      <Typography variant="h6" fontWeight={500} fontFamily="WFVisualSans">
        {t('dashboard.claimDetailsBlock.title')}
      </Typography>
      {!editClaim && notNullFields && (
        <StyledEditSectionButton
          variant="outlined"
          size="small"
          onClick={() => {
            toggleEdit(editClaim, setEditClaim);
            handleResetForm();
          }}
          endIcon={<EditIcon />}
        >
          {t('common.editClaimDetails')}
        </StyledEditSectionButton>
      )}
      <Box display="flex" gap={3.5} m={0}>
        {!claimDateField && !claimDate && (
          <AddDetailsButton onClick={handleAddClaimDate} text={t('dashboard.claimDetailsBlock.addDate')} />
        )}
        {!idNumber && !claimIdNumber && (
          <AddDetailsButton onClick={handleAddClaimIdNumber} text={t('dashboard.claimDetailsBlock.addNumber')} />
        )}
        {claimDateField && !editClaim && (
          <Box display="flex" flexDirection="column" gap={0.5} justifyContent="center">
            <StyledTitle variant="subtitle2">{t('dashboard.addClaimDateModal.title')}</StyledTitle>
            <Typography variant="subtitle1" fontWeight={400}>
              {convertIsoToReadable(claimDateField, { fullYear: true })}
            </Typography>
          </Box>
        )}
        {idNumber && !editClaim && (
          <Box display="flex" flexDirection="column" gap={0.5} justifyContent="center">
            <StyledTitle variant="subtitle2">{t('dashboard.addClaimIdNumberModal.title')}</StyledTitle>
            <Typography variant="subtitle1" fontWeight={400}>
              {idNumber}
            </Typography>
          </Box>
        )}
      </Box>
      {editClaim && <ClaimedDetailsEdit loading={loading} />}
    </Box>
  );
};

export default memo(ClaimedDetails);
