import { FC, memo, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Trans, useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { BillingPeriod } from '@constants/enums';
import { ROUTING } from '@constants/routing';
import { useBilling } from '@hooks/api/useBilling';
import { useUser } from '@hooks/api/useUser';
import { useLogout } from '@hooks/useLogout';
import { useSubscription } from '@hooks/useSubscription';
import { StyledLink } from '@pages/styled';
import Card from '@pages/SubscribePage/components/Card';
import SubscriptionSummary from '@pages/SubscribePage/components/SubscriptionSummary';
import { cardsConfig, featureList } from '@pages/SubscribePage/config';
import { AUD } from '@pages/SubscribePage/constants';
import {
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
  StyledFloatingBox,
  StyledLogoutButton,
  StyledSummaryBox,
  StyledTypographyContrast,
  StyledTypographyPrimaryMain,
} from '@pages/SubscribePage/styled';
import { PaymentInfo } from '@pages/SubscribePage/types';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import LogoutIcon from '@mui/icons-material/Logout';
import { Box, Grid, Typography } from '@mui/material';

const SubscribePage: FC = () => {
  const [billingPeriod, setBillingPeriod] = useState<BillingPeriod>(BillingPeriod.YEAR);
  const [expanded, setExpanded] = useState(false);
  const { t } = useTranslation();
  const { data } = useUser();
  const { data: subscriptionData, isLoadingSubscription } = useSubscription();
  const { logout } = useLogout();
  // const navigate = useNavigate();
  const { handleSubscribe, isLoadingBilling } = useBilling();

  const trialEnded = subscriptionData?.status === 'inactive';
  // const isSubscribed = subscriptionData?.subscription_type && subscriptionData?.days_left;
  const isSamePackage = subscriptionData?.subscription_type === billingPeriod;

  const getPaymentInfo = useMemo((): PaymentInfo => {
    const name = `${data?.first_name || ''}${data?.last_name ? ` ${data?.last_name}` : ''}`;
    const email = data?.email || '';

    const selectedCard = cardsConfig.find(card => card.plan === billingPeriod);

    if (!selectedCard) {
      throw new Error(`No card configuration found for billing period: ${billingPeriod}`);
    }

    const amount = selectedCard.config.price.toFixed(2);
    const frequency = t(selectedCard.config.title);

    return {
      name,
      email,
      amount: `$${amount} ${AUD}`,
      frequency,
      plan: billingPeriod,
    };
  }, [billingPeriod, data, t]);

  const handlePeriodChange = (period: BillingPeriod) => {
    setBillingPeriod(period);
  };

  const handleProceed = () => handleSubscribe({ period: billingPeriod });

  const handleExpand = () => {
    setExpanded(!expanded);
  };

  if (isLoadingSubscription) return null;

  // ToDo temporary disable this check
  // if (isSubscribed) {
  //   navigate(ROUTING.ROOT, { replace: true });
  // }

  return (
    <>
      <Helmet>
        <title>{t('common.helmetTitles.subscribePage')}</title>
      </Helmet>
      <Grid container columns={12} width="auto" height="100vh">
        <Grid item xs={12} md={7.5} sm={7} sx={{ position: 'relative' }}>
          <StyledFloatingBox />
          <Box
            display="flex"
            flexDirection="column"
            width="100%"
            height="100%"
            pt="60px"
            pl={{ xs: '20px', md: '85px' }}
            pr={{ xs: '20px', md: '100px' }}
            pb={3}
            sx={{ position: 'relative' }}
          >
            {/* NOT ENDED TRIAL */}
            {!trialEnded && (
              <>
                <StyledLink component={RouterLink} to={`${ROUTING.ROOT}`} replace aria-label="back button">
                  <Box display="flex" alignItems="center" gap={0.5} ml="-4px">
                    <ChevronLeftIcon />{' '}
                    <Typography fontSize={16} fontWeight={500}>
                      {t('common.back')}
                    </Typography>
                  </Box>
                </StyledLink>

                <StyledTypographyPrimaryMain mt="25px" fontWeight={500} fontSize={38} fontFamily="WFVisualSans">
                  {t('dashboard.subscribePage.title')}
                </StyledTypographyPrimaryMain>

                <StyledTypographyContrast mt={2.5} fontWeight={500} fontSize={28} fontFamily="WFVisualSans">
                  {t('dashboard.subscribePage.subtitle')}
                </StyledTypographyContrast>
              </>
            )}
            {/* TRIAL ENDED */}
            {trialEnded && (
              <>
                <Box display="flex" justifyContent="space-between" alignItems="baseline" maxWidth="925px">
                  <StyledTypographyPrimaryMain mt="20px" fontWeight={500} fontSize={38} fontFamily="WFVisualSans">
                    {t('dashboard.subscribePage.trialEnded.title')}
                  </StyledTypographyPrimaryMain>
                  <StyledLogoutButton variant="text" color="primary" endIcon={<LogoutIcon />} onClick={logout}>
                    <Typography fontWeight={500} fontSize={14}>
                      {t('auth.logout')}
                    </Typography>
                  </StyledLogoutButton>
                </Box>
                <StyledTypographyContrast
                  mt={2.5}
                  fontWeight={500}
                  fontSize={28}
                  fontFamily="WFVisualSans"
                  maxWidth={450}
                >
                  {t('dashboard.subscribePage.trialEnded.subtitle')}
                </StyledTypographyContrast>
              </>
            )}

            <StyledTypographyContrast mt={6} mb={2} fontWeight={500} fontSize={20} fontFamily="WFVisualSans">
              {t('dashboard.subscribePage.billingCycleTitle')}
            </StyledTypographyContrast>

            {/* CARDS */}
            <Box display="flex" gap="42px" flexDirection={{ xs: 'column', md: 'row' }} maxWidth="900px">
              {cardsConfig.map(card => (
                <Card
                  key={card.id}
                  config={card.config}
                  plan={card.plan}
                  isSelected={card.plan === billingPeriod}
                  onPeriodChange={handlePeriodChange}
                />
              ))}
            </Box>

            {/* ACCORDION */}
            <Box my={5}>
              <StyledAccordion expanded={expanded} onChange={handleExpand}>
                <StyledAccordionSummary
                  expandIcon={<ArrowDropDownIcon />}
                  aria-controls="learn-more-content"
                  id="learn-more-header"
                >
                  <StyledTypographyContrast variant="h6" fontFamily="WFVisualSans" fontWeight={500}>
                    {t('dashboard.subscribePage.learnMoreTitle')}
                  </StyledTypographyContrast>
                  <StyledTypographyPrimaryMain fontSize={14}>
                    {expanded ? t('common.showLess') : t('common.learnMore')}
                  </StyledTypographyPrimaryMain>
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                  <StyledTypographyContrast>
                    {t('dashboard.subscribePage.learnMoreContent.first')}
                  </StyledTypographyContrast>
                  <StyledTypographyContrast>
                    <Trans
                      i18nKey={t('dashboard.subscribePage.learnMoreContent.second')}
                      components={{ bold: <strong /> }}
                    />
                  </StyledTypographyContrast>
                  <StyledTypographyContrast>
                    <Trans
                      i18nKey={t('dashboard.subscribePage.learnMoreContent.third')}
                      components={{ bold: <strong /> }}
                    />
                  </StyledTypographyContrast>
                </StyledAccordionDetails>
              </StyledAccordion>
            </Box>

            {/* FEATURES */}
            <Box mb={5} maxWidth="900px">
              <StyledTypographyContrast mb={3} variant="h6" fontFamily="WFVisualSans" fontWeight={500}>
                {t('dashboard.subscribePage.includes')}
              </StyledTypographyContrast>
              <Grid container spacing={4}>
                {featureList.map(({ label, Icon }) => (
                  <Grid item xs={12} sm={6} md={6} key={label}>
                    <Box display="flex" alignItems="center" gap={1}>
                      <Icon color="primary" fontSize="large" />
                      <Typography>{t(label)}</Typography>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Box>
        </Grid>
        <Grid item md={4.5} xs={12} sm={5}>
          <StyledSummaryBox
            display="flex"
            flexDirection="column"
            width="100%"
            height="100%"
            pt="73px"
            pl={{ sm: '20px', md: '75px' }}
            pr={{ sm: '20px', md: '80px' }}
          >
            <SubscriptionSummary
              info={getPaymentInfo}
              handleProceed={handleProceed}
              isLoadingMutation={isLoadingBilling}
              isSamePackage={isSamePackage}
            />
          </StyledSummaryBox>
        </Grid>
      </Grid>
    </>
  );
};

export default memo(SubscribePage);
