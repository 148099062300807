import { FC, memo, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useApiInvoicesListQuery } from '@api/api';
import { ROUTING } from '@constants/routing';
import NiceModal from '@ebay/nice-modal-react';
import useDownloadFile from '@hooks/api/useDownloadFile';
import { useUser } from '@hooks/api/useUser';
import { usePageFilterUrlParams } from '@hooks/usePageFilterUrlParams';
import { useSessionId } from '@hooks/useSessionId';
import { useSubscription } from '@hooks/useSubscription';
import InvoicesTable from '@pages/Invoices/components/InvoicesTable';
import SearchField from '@pages/Invoices/components/SearchField';
import PreviewInvoice from '@pages/Invoices/modals/PreviewInvoice';
import { StyledControlsBox, StyledFiltersButton, StyledLoadingButton } from '@pages/Invoices/styled';
import { capitalize } from '@utils/formatInitials';

import FilterListIcon from '@mui/icons-material/FilterList';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { Box, Typography } from '@mui/material';
import { GridPaginationModel } from '@mui/x-data-grid';
import FloatingViewButton from '@components/FloatingViewButton';

import { NotificationModalId } from '@/shared/NotificationModal/NotificationModal';

const Invoices: FC = () => {
  const { page, limit, setPage, setLimit } = usePageFilterUrlParams();
  const { t } = useTranslation();
  const { exportExcelFiles, isLoadingExcel } = useDownloadFile();
  const navigate = useNavigate();
  const { data: userData, isLoading: isLoadingUser } = useUser();
  const { sessionId } = useSessionId();
  const { isLoadingSubscription } = useSubscription();

  const handlePaginationModelChange = (newPaginationModel: GridPaginationModel) => {
    setPage(newPaginationModel.page);
    setLimit(newPaginationModel.pageSize);
  };

  const {
    data: invoicesData,
    isLoading: isLoadingInvoices,
    isFetching,
  } = useApiInvoicesListQuery({
    limit,
    offset: page * limit,
  });
  const isLoading = [isLoadingInvoices, isFetching, isLoadingUser, isLoadingSubscription].some(Boolean);

  useEffect(() => {
    if (userData && sessionId) {
      NiceModal.show(NotificationModalId, {
        title: (
          <Trans
            i18nKey="dashboard.notifications.subscribe.success.modalTitle"
            values={{ username: capitalize(userData.first_name) }}
          />
        ),
        confirmButtonText: t('dashboard.notifications.subscribe.success.confirmButtonText'),
      });
    }
  }, [sessionId, t, userData]);

  return (
    <>
      <Helmet>
        <title>{t('common.helmetTitles.invoicesReceipts')}</title>
      </Helmet>

      <Box aria-label="invoices page" display="flex" gap={2} flexDirection="column" height="100%">
        <StyledControlsBox aria-label="title area" display="flex" justifyContent="space-between">
          <Box display="flex" gap={3}>
            <StyledFiltersButton
              disableRipple
              disableTouchRipple
              variant="text"
              startIcon={<FilterListIcon color="primary" />}
            >
              <Typography>{t('dashboard.filters')}</Typography>
            </StyledFiltersButton>
            <StyledLoadingButton
              disableRipple
              disableTouchRipple
              variant="text"
              loading={isLoadingExcel}
              startIcon={<SaveAltIcon color="primary" />}
              onClick={exportExcelFiles}
            >
              <Typography>{t('dashboard.exportCsv')}</Typography>
            </StyledLoadingButton>
          </Box>
          <SearchField />
        </StyledControlsBox>

        <Box sx={{ height: `calc(100% - 175px)`, position: 'relative' }}>
          <InvoicesTable
            invoices={invoicesData}
            isLoading={isLoading}
            handlePageChange={handlePaginationModelChange}
            paginationModel={{ page, pageSize: limit }}
          />
          <Box position="absolute" right={16} bottom={16} zIndex={1000}>
            <FloatingViewButton
              locale="dashboard.navigation.uploadInvoice"
              icon={UploadFileIcon}
              onClick={() => navigate(`/${ROUTING.INVOICE_UPLOAD}`)}
              size="small"
            />
          </Box>
        </Box>
        <PreviewInvoice />
      </Box>
    </>
  );
};

export default memo(Invoices);
