import NiceModal, { useModal } from '@ebay/nice-modal-react';

import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, ButtonProps, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';

import { StyledModal } from '../styles';

const NotificationModal = NiceModal.create(
  ({
    title,
    subtitle,
    variant = 'contained',
    confirmButtonText,
  }: {
    title: string | JSX.Element;
    subtitle?: string;
    confirmButtonText: string;
    variant: ButtonProps['variant'];
  }) => {
    const modal = useModal();

    const handleConfirmClick = () => {
      modal.resolve(true);
      modal.remove();
    };
    const handleCancelClick = async () => {
      modal.resolve(false);
      modal.remove();
    };

    return (
      <StyledModal open={modal.visible} size="small" onClose={handleCancelClick}>
        <Box position="absolute" top={15} right={15}>
          <IconButton onClick={handleCancelClick}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box padding="32px 45px" display="flex" flexDirection="column" alignItems="center">
          <Typography
            maxWidth={300}
            variant="h5"
            mt="30px"
            mb="24px"
            fontSize={24}
            textAlign="center"
            fontFamily="WFVisualSans"
            color="rgba(37, 23, 95, 1)"
          >
            {title}
          </Typography>
          {subtitle && (
            <Typography variant="h5" mb="24px" textAlign="center" fontFamily="WFVisualSans">
              {subtitle}
            </Typography>
          )}
          <Box display="flex" justifyContent="space-between" flexBasis={1} mt={4}>
            <Button fullWidth variant={variant} color="primary" onClick={handleConfirmClick}>
              {confirmButtonText}
            </Button>
          </Box>
        </Box>
      </StyledModal>
    );
  },
);

export const NotificationModalId = 'NotificationModal';

NiceModal.register(NotificationModalId, NotificationModal);
