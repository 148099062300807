import { Grid } from '@mui/material';
import { styled, Theme } from '@mui/material/styles';
import { GridProps } from '@mui/system';

export const StyledInfoColumnGrid = styled(Grid)<GridProps & { theme?: Theme }>(({ theme }) => ({
  backgroundColor: theme.colors.white,
  borderRadius: '4px',
  zIndex: 2,
  position: 'relative',
  [theme.breakpoints.up('lg')]: {
    '&::after': {
      content: '""',
      position: 'absolute',
      top: '41px',
      bottom: '30px',
      right: 0,
      width: '2px',
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
    },
  },
}));

export const StyledEditableColumnGrid = styled(Grid)<GridProps & { theme?: Theme }>(({ theme }) => ({
  backgroundColor: theme.colors.white,
  zIndex: 1,
}));
