import { FC, memo, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { InvoiceRead } from '@api/api';
import { DASH_SYMBOL, MAX_DESCRIPTION_CHARACTERS } from '@constants/common';
import MissingDetailsDropdowns from '@pages/InvoiceDetailsNew/components/EditableColumn/components/MissingDetailsDropdowns';
import {
  StyledAlertTextField,
  StyledCheckboxFormControlLabel,
} from '@pages/InvoiceDetailsNew/components/EditableColumn/styled';
import {
  MISSING_DESCRIPTION_FIELD,
  MISSING_MORE_THAN_ONE_DAY_FIELD,
  MISSING_SERVICE_END_DATE_FIELD,
  MISSING_SERVICE_EXACT_DATE_FIELD,
  MISSING_SERVICE_START_DATE_FIELD,
  MISSING_VENDOR_NAME_FIELD,
  VENDOR_NAME_FIELD,
} from '@pages/InvoiceDetailsNew/constants';
import { getDatePickerInputStyles } from '@pages/InvoiceDetailsNew/utils';

import ErrorIcon from '@mui/icons-material/Error';
import EventIcon from '@mui/icons-material/Event';
import { Box, Checkbox, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import LoadingButton from '@components/LoadingButton';
import { theme } from '@components/theme';

const MissingDetailsForm: FC<{ loading: boolean; data?: InvoiceRead }> = ({ loading, data }) => {
  const { t } = useTranslation();
  const { control, setError, trigger, watch } = useFormContext();
  const missingDescField = watch(MISSING_DESCRIPTION_FIELD);
  const missingProviderField = watch(MISSING_VENDOR_NAME_FIELD);
  const missingMoreThanOneDay = watch(MISSING_MORE_THAN_ONE_DAY_FIELD);
  const missingStartDay = watch(MISSING_SERVICE_START_DATE_FIELD);
  const missingEndDay = watch(MISSING_SERVICE_END_DATE_FIELD);
  const missingExactDay = watch(MISSING_SERVICE_EXACT_DATE_FIELD);

  const noDatePresent = !data?.service_exact_date && !data?.service_start_date && !data?.service_end_date;
  const startEndDatesNotFilled = !missingStartDay || !missingEndDay;

  useEffect(() => {
    if (missingDescField.length > MAX_DESCRIPTION_CHARACTERS) {
      setError(MISSING_DESCRIPTION_FIELD, {
        type: 'manual',
        message: `${missingDescField.length - MAX_DESCRIPTION_CHARACTERS} characters over the ${MAX_DESCRIPTION_CHARACTERS} character limit.`,
      });
      trigger(MISSING_DESCRIPTION_FIELD);
    }
    if (missingProviderField.length > MAX_DESCRIPTION_CHARACTERS) {
      setError(VENDOR_NAME_FIELD, {
        type: 'manual',
        message: `${missingProviderField.length - MAX_DESCRIPTION_CHARACTERS} characters over the ${MAX_DESCRIPTION_CHARACTERS} character limit.`,
      });
      trigger(VENDOR_NAME_FIELD);
    }
  }, [missingDescField, missingProviderField, setError, trigger]);

  return (
    <Box display="flex" flexDirection="column" gap={3} sx={{ '& .MuiFormControl-root': { maxWidth: 500 } }}>
      <MissingDetailsDropdowns />

      {!data?.description && (
        <Controller
          control={control}
          name={MISSING_DESCRIPTION_FIELD}
          render={({ field, fieldState }) => (
            <Box display="flex" gap={2} width="100%" alignItems="center">
              <StyledAlertTextField
                fullWidth
                variant="outlined"
                label={t('dashboard.updateDetails.descriptionTitle')}
                type="text"
                autoComplete="off"
                inputProps={{
                  'aria-required': 'true',
                  'aria-label': 'description input field',
                }}
                error={Boolean(fieldState.error)}
                helperText={
                  fieldState.error ? (
                    t(fieldState.error.message as string)
                  ) : (
                    <Box display="flex">
                      <Typography variant="caption">{t('dashboard.updateDetails.descriptionHelpText')}</Typography>
                    </Box>
                  )
                }
                {...field}
                onBlur={() => {
                  trigger(MISSING_DESCRIPTION_FIELD);
                }}
              />
              {!field.value && (
                <Box mt="12px">
                  <ErrorIcon color="warning" />
                </Box>
              )}
            </Box>
          )}
        />
      )}

      {!data?.vendor_name && (
        <Controller
          control={control}
          name={MISSING_VENDOR_NAME_FIELD}
          render={({ field, fieldState }) => (
            <Box display="flex" gap={2} width="100%">
              <StyledAlertTextField
                fullWidth
                variant="outlined"
                placeholder={t('dashboard.updateDetails.providerTitle')}
                type="text"
                autoComplete="off"
                inputProps={{
                  'aria-required': 'true',
                  'aria-label': 'provider input field',
                }}
                error={Boolean(fieldState.error)}
                helperText={
                  fieldState.error ? (
                    t(fieldState.error.message as string)
                  ) : (
                    <Box display="flex" justifyContent="flex-end">
                      <Typography variant="caption">{`${field?.value?.length}/${MAX_DESCRIPTION_CHARACTERS}`}</Typography>
                    </Box>
                  )
                }
                {...field}
                onBlur={() => {
                  trigger(MISSING_VENDOR_NAME_FIELD);
                }}
              />
              {!field.value && (
                <Box mt="12px">
                  <ErrorIcon color="warning" />
                </Box>
              )}
            </Box>
          )}
        />
      )}

      {noDatePresent && (
        <Box display="flex" flexDirection="column" gap={1} sx={{ '& .MuiFormControl-root': { flexGrow: 1 } }}>
          {!missingMoreThanOneDay && (
            <Controller
              control={control}
              name={MISSING_SERVICE_EXACT_DATE_FIELD}
              render={({ field, fieldState }) => (
                <Box display="flex" gap={2} width="100%">
                  <DatePicker
                    {...field}
                    label={t('dashboard.updateDetails.serviceDateField')}
                    slots={{
                      openPickerIcon: EventIcon,
                    }}
                    slotProps={{
                      textField: () => ({
                        sx: {
                          svg: { color: theme.colors.primary.main },
                          fontWeight: 400,
                          '& .MuiInputBase-root': getDatePickerInputStyles(missingExactDay),
                        },
                        variant: 'outlined',
                        error: Boolean(fieldState.error),
                        helperText:
                          fieldState.error &&
                          t(`common.errors.${fieldState.error.type}`, { defaultValue: fieldState.error.message }),
                      }),
                    }}
                  />
                  {!field.value && (
                    <Box display="flex" alignItems="center">
                      <ErrorIcon color="warning" />
                    </Box>
                  )}
                </Box>
              )}
            />
          )}

          {missingMoreThanOneDay && (
            <Box display="flex" gap={2} alignItems="center">
              <Box display="flex" gap={1.21} alignItems="center" maxWidth={550}>
                <Controller
                  control={control}
                  name={MISSING_SERVICE_START_DATE_FIELD}
                  render={({ field, fieldState }) => (
                    <DatePicker
                      {...field}
                      label={t('dashboard.updateDetails.serviceStartDateField')}
                      slots={{
                        openPickerIcon: EventIcon,
                      }}
                      slotProps={{
                        textField: () => ({
                          sx: {
                            svg: { color: theme.colors.primary.main },
                            fontWeight: 400,
                            '& .MuiInputBase-root': getDatePickerInputStyles(missingStartDay),
                          },
                          variant: 'outlined',
                          error: Boolean(fieldState.error),
                          helperText:
                            fieldState.error &&
                            t(`common.errors.${fieldState.error.type}`, { defaultValue: fieldState.error.message }),
                        }),
                      }}
                    />
                  )}
                />
                <Box>{DASH_SYMBOL}</Box>
                <Controller
                  control={control}
                  name={MISSING_SERVICE_END_DATE_FIELD}
                  render={({ field, fieldState }) => (
                    <DatePicker
                      {...field}
                      label={t('dashboard.updateDetails.serviceEndDateField')}
                      slots={{
                        openPickerIcon: EventIcon,
                      }}
                      slotProps={{
                        textField: () => ({
                          sx: {
                            svg: { color: theme.colors.primary.main },
                            fontWeight: 400,
                            '& .MuiInputBase-root': getDatePickerInputStyles(missingEndDay),
                          },
                          variant: 'outlined',
                          error: Boolean(fieldState.error),
                          helperText:
                            fieldState.error &&
                            t(`common.errors.${fieldState.error.type}`, { defaultValue: fieldState.error.message }),
                        }),
                      }}
                    />
                  )}
                />
              </Box>
              {startEndDatesNotFilled && (
                <Box display="flex" alignItems="center">
                  <ErrorIcon color="warning" />
                </Box>
              )}
            </Box>
          )}
          {/* CHECKBOX */}
          <StyledCheckboxFormControlLabel
            control={
              <Controller
                control={control}
                name={MISSING_MORE_THAN_ONE_DAY_FIELD}
                render={({ field }) => (
                  <Checkbox {...field} checked={field.value} onChange={e => field.onChange(e.target.checked)} />
                )}
              />
            }
            labelPlacement="end"
            label={t('dashboard.updateDetails.moreThanOneDayLabel')}
          />
        </Box>
      )}

      <LoadingButton
        fullWidth
        type="submit"
        color="secondary"
        loading={loading}
        sx={{ width: '150px', padding: '5px 10px' }}
      >
        {t('common.save')}
      </LoadingButton>
    </Box>
  );
};

export default memo(MissingDetailsForm);
