import { Box, BoxProps, Typography, TypographyProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import LoadingButton from '@components/LoadingButton';

export const StyledPaymentDetailsBox = styled(Box)<BoxProps & { isLast?: boolean }>(({ isLast }) => ({
  borderBottom: isLast ? 'none' : '1px solid #E4E4E4',
}));

export const StyledWrappedTypography = styled(Typography)<TypographyProps>({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: '200px',
});

export const StyledProceedButton = styled(LoadingButton)(({ disabled }) => ({
  backgroundColor: disabled ? 'lightcoral !important' : 'rgba(232, 213, 255, 1)',
  borderRadius: '5px',
}));
