import { FC, memo } from 'react';
import { Document, Page } from 'react-pdf';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import {
  PageNumberBlock,
  StyledPreviewButtonBox,
} from '@pages/InvoiceDetailsNew/components/PreviewColumn/components/DocumentPreview/components/styled';
import { StyledDocViewerWrapper } from '@pages/InvoiceDetailsNew/components/PreviewColumn/components/DocumentPreview/styled';
import { PreviewDocButton } from '@pages/InvoiceDetailsNew/components/PreviewColumn/types';

import { Box } from '@mui/material';

const PreviewButton: FC<PreviewDocButton> = ({ fileName, blob, onClick, isPdf, isSelected, pageNumber }) => {
  const docs = [{ blob: blob as Blob, fileName: fileName as string }];

  return (
    <StyledPreviewButtonBox height={122} width={100} isSelected={isSelected} onClick={onClick}>
      {!isPdf && (
        <StyledDocViewerWrapper height="115px">
          <DocViewer
            pluginRenderers={DocViewerRenderers}
            documents={docs.map(file => ({
              uri: window.URL.createObjectURL(file.blob),
              fileName: file.fileName,
            }))}
            config={{
              header: {
                disableHeader: false,
                disableFileName: true,
                retainURLParams: true,
              },
            }}
          />
        </StyledDocViewerWrapper>
      )}
      {isPdf && (
        <Box m={0} p={0} position="relative">
          <Document file={blob}>
            <Page pageNumber={pageNumber} height={115} />
          </Document>
          <PageNumberBlock>{pageNumber}</PageNumberBlock>
        </Box>
      )}
    </StyledPreviewButtonBox>
  );
};

export default memo(PreviewButton);
